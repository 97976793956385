import { Card } from "@mui/material";
import BookingCard from "./BookinCard/BookingCard";

const MyBooking = () => {
  return (
    <>
      <div className="w-full py-6 flex flex-col gap-2">
        <p className="font-bold text-xl">My Booking</p>
        <p className="font-text text-sm">Dashboard - Edit Profile</p>
      </div>
      <Card className="w-full p-6 flex flex-col gap-6">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-xl">Current Booking</p>
        </div>
        <BookingCard />
      </Card>
      <Card className="w-full p-6 flex flex-col">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-xl">Single Booking</p>
        </div>
      </Card>
    </>
  );
};

export default MyBooking;
