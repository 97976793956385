import React, {useEffect, useState} from "react";
import Counter from "../../../components/Counter";


const CounterWrapper = ({onChange, addon, defaultValue}) => {
    const [childSeatCount, setChildSeatCound] = useState(defaultValue);
    // useEffect(() => {
    //     onChange(childSeatCount, addon);
    // }, [childSeatCount]);
    return (
        <Counter count={childSeatCount} setCount={(value) => {
            setChildSeatCound(value);
            onChange(value, addon);
        }} />
    )
}

export default CounterWrapper;
