import { toast } from "react-toastify";
import { stringConstant, toast_options } from "../../utils/constants";
import {
    saveprofile_individual,
    saveprofile_corporate,
    save_password,
    payment_details_update,
    payment_details_update_get,
    profile_update,
    get_my_booking,
    get_dashboard,
    cancel_booking, profile_licence, cancel_list, vehicle_income, vehicle_expenses,
} from "../../utils/webAPIs";
import yaxios from "../../utils/yaxios";
import { SET_LOADING } from "../type";
import fmsaxios from "../../utils/fmsaxios";
import {getInfoByToken} from "./authAction";

export const saveProfile = (dispatch, postData, navigate) => {
  fmsaxios
    .post(
        profile_update,
        postData
    )
    .then((res) => {
        dispatch({ type: SET_LOADING, payload: false });
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.success(res.data.message, toast_options);
          getInfoByToken(dispatch, true, navigate);
        } else {
          toast.warning(res.data.message, toast_options);
        }
      } else {
        toast.error(stringConstant.failed_update, toast_options);
      }
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, payload: false });
      toast.error(stringConstant.request_error, toast_options);
    });
};

export const savePassword = (dispatch, postData) => {
  yaxios
    .post(save_password, postData)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.success(res.data.message, toast_options);
        } else {
          toast.warning(res.data.message, toast_options);
        }
      } else {
        toast.error(stringConstant.failed_update, toast_options);
      }
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, payload: false });
      toast.error(stringConstant.request_error, toast_options);
    });
};

export const paymentDetailsUpdate = (dispatch, postData) => {
  fmsaxios
      .post(payment_details_update, postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === "success") {
            toast.success(res.data.message, toast_options);
          } else {
            toast.warning(res.data.message, toast_options);
          }
        } else {
          toast.error(stringConstant.failed_update, toast_options);
        }
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch((err) => {
        dispatch({ type: SET_LOADING, payload: false });
        toast.error(stringConstant.request_error, toast_options);
      });
};

export const paymentDetailsGet = (dispatch, postData) => {
  return new Promise((resolve, reject) => {
      fmsaxios
          .post(payment_details_update_get, postData)
          .then((res) => {
              dispatch({ type: SET_LOADING, payload: false });
              if (res.status === 200) {
                  resolve(res.data);
              } else {
                  toast.error(stringConstant.failed_update, toast_options);
                  reject(res.data);
              }
          })
          .catch((err) => {
              dispatch({ type: SET_LOADING, payload: false });
              toast.error(stringConstant.request_error, toast_options);
              reject(err);
          });
  })
};


export const getMyBooking = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        fmsaxios.post(get_my_booking, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}


export const getDashboardAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        fmsaxios.post(get_dashboard, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
};


export const cancelBookingAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        let route = `${cancel_booking}/${postData.booking_id}/cancel`
        fmsaxios.post(route, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            console.log(res.data)
            if (res.status === 200) {
                if (res.data.success) {
                    resolve(res.data.data);
                } else {
                    toast.error(res.data.message, toast_options);
                    reject(res.data);
                }
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}

export const profileLicencesAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        fmsaxios.post(profile_licence, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}

export const cancelListAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        fmsaxios.post(cancel_list, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}

export const vehicleIncomeAndExpenseAPI = (dispatch, postData, type) => {
    return new Promise((resolve, reject) => {
        dispatch({type: SET_LOADING, payload: true});
        let url = ''
        if (type === 'income') {
            url = vehicle_income + '?page=1'
        } else {
            url = vehicle_expenses
        }
        fmsaxios.post(url, postData).then((res) => {
            dispatch({type: SET_LOADING, payload: false});
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}
