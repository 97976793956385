import logo from '../assets/images/logo1.png'
import FooterBG from '../assets/images/FooterBG.png'
import logo1 from '../assets/images/logo1.png'
import recar from '../assets/images/redcar.jpg'

const Images = {
    logo,
    FooterBG,
    logo1,
    recar
};

export default Images;
