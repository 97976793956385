import RButton from "../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import BookingModal from "./BookingModal";
import {useState} from "react";

const ShareCard = (props) => {
  const { image, title, text } = props;

  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const user = useSelector((state) => state.auth?.user);
  return (
    <div
      className="max-w-[400px] w-full rounded-lg overflow-hidden shadow-2xl shadow-neutral-400 relative"
      {...props}
    >
      <img src={image} alt="" className="w-full" />
      <div className="w-full p-4 flex flex-col gap-4 pb-20">
        <p className="text-lg font-bold">{title}</p>
        <p className="text-base">{text}</p>
        <Link to={user ? "#" : "/signup"} onClick={() => {
            if (user) {
                setBookingModalVisible(true);
            }
        }}>
          <RButton
              isfullradius={true}
              style={{ position: "absolute", bottom: "15px" }}
          >
          <span className="flex items-center gap-4 px-10 text-base font-normal">
            Get Started
            <BsArrowUpRight />
          </span>
          </RButton>
        </Link>
      </div>

      <BookingModal
          visible={bookingModalVisible}
          setVisible={setBookingModalVisible}
      />
    </div>
  );
};

export default ShareCard;
