"use client";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  LiaLongArrowAltLeftSolid,
  LiaLongArrowAltRightSolid,
} from "react-icons/lia";

const SlideCard = () => {
  const sliderRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: handleAfterChange,
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef} className=" w-full">
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
        <div>
          <h1 className="text-[#181A1F] text-6xl text-center">Testimonials</h1>
          <p className="text-lg font-semibold mt-6 font-text">
            For my return pickup from the hotel to airport, I was assigned 4 drivers instead of the original 3. Was not provided the 4th drivers contact information and he was late for pickup causing delay of departure to the airport. For 20 minutes, I was told he was “at the light”.
          </p>
        </div>
      </Slider>
      <div className="flex items-center w-full gap-4 mt-4">
        <div
          className="p-3 bg-white rounded-full cursor-pointer w-max border"
          onClick={prev}
        >
          <LiaLongArrowAltLeftSolid />
        </div>
        <div
          className="p-3 bg-white rounded-full cursor-pointer w-max border"
          onClick={next}
        >
          <LiaLongArrowAltRightSolid />
        </div>
        <p>{currentSlide + 1}/6</p>
      </div>
    </>
  );
};

export default SlideCard;
