import axios from "axios";
import fmsaxios from "../../utils/fmsaxios";
import {SET_LOADING} from "../type";
import {fcm_contact} from "../../utils/webAPIs";
import {stringConstant, toast_options} from "../../utils/constants";
import { toast } from "react-toastify";

export const submitContact = (dispatch, postData, setClearForm) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.post(fcm_contact, postData).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        setClearForm(true);
        if (res.status === 200) {
            toast.success(res.data.message, toast_options);
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
    }).catch((err) => {
        dispatch({type: SET_LOADING, payload: false});
        console.log(err)
    })
}
