// Auth
export const signup_individual =
  "CustomerRestController/customer_signup_individual";
export const signup_corporate =
  "CustomerRestController/customer_signup_corporate";
export const signin = "CustomerRestController/customer_authenticate";
export const validate_token = "CustomerRestController/customer_validateToken";

// Fleet
export const get_fleet = "FleetRestController/fleet";

// My Account
export const saveprofile_individual =
  "CustomerRestController/customer_update_individual";
export const saveprofile_corporate =
  "CustomerRestController/customer_update_corporate";
export const save_password =
  "change-password";

// Booking
export const get_wholeday_booking = "BookingRestController/wholeday_booking"
export const get_hourly_booking = "BookingRestController/hourly_booking"


// auth apis
export const fms_register = 'register'
export const fms_login = 'login'
export const forgot_password = 'forgot-password'



// profile apis
export const fms_profile = 'profile'
export const payment_details_update = 'update-account-details'
export const payment_details_update_get = 'update-account-details/get'
export const profile_update = 'profile/update'
export const get_my_booking = 'my-booking'
export const get_dashboard = 'dashboard'
export const cancel_booking = 'booking'
export const profile_licence = 'profile/licence'
export const cancel_list = 'cancel/list'
export const vehicle_income = 'vehicle/income'
export const vehicle_expenses = 'vehicle/expense'


// fms fleet
export const fms_fleet = 'fleet'


//booking
export const get_fms_vehicle = 'vehicles'
export const addonList = 'get-addon-list'
export const create_booking = 'booking/create'
export const get_distance = 'get-distance'
export const booking_payment = 'booking/payment'

// complete details for booking
export const fms_booking = '/booking'


// assets url
export const fms_images = 'https://fleet.yoksghana.com/uploads/vehicles/'

// global
export const fms_branches = 'branches'
export const fcm_vehicles_types = 'vehicle-types'
export const fcm_vehicle_by_type = 'fetch-vehicle-list-by-type'
export const fcm_settings = 'settings'
export const fcm_contact = 'contact'


// vehicle apis
export const fms_vehicle_create = 'vehicle/create'
export const get_maker = 'vehicle-makes'
export const get_model = 'vehicle-models'
export const get_colors = 'vehicle-colors'
export const get_vehicles_list = 'vehicle/list'
export const vehicle_update = 'vehicle/update'


