import { useState, useEffect } from "react";
import RButton from "../../../components/RButton";
import {
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { BsArrowUpRight } from "react-icons/bs";
import {
  createVehicleAPI,
  getSingleVehicleAPI,
  getVehicleVariables,
  vehicleUpdateAPI
} from "../../../redux/actions/vehiclesActions";
import {useDispatch} from "react-redux";
import {getCookie} from "../../../utils/constants";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
const NewVehicle = () => {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2023);
  const dispatch = useDispatch();
  let { id } = useParams();

  const navigate = useNavigate();
  const [variables, setVariables] = useState({
    makers: [],
    models: [],
    colors: [],
  });
  const [selectedMaker, setSelectedMaker] = useState('');
  const [selectedModal, setSelectedModal] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [engineType, setEngineType] = useState(''); //select option
  const [plate, setPlate] = useState('');
  const [horsePower, setHorsePower] = useState('');
  const [doors, setDoors] = useState('');
  const [luggage, setLuggage] = useState('');
  const [seaters, setSeaters] = useState('');
  const [aircondition, setAircondition] = useState(''); //select Y/N
  const [transmissionType, setTransmissionType] = useState(''); // select Automatic,Manual
  const [vin, setVin] = useState('');
  const [licencePlate, setLicencePlate] = useState('');
  const [mileage, setMileage] = useState('');
  const [initMileage, setInitMileage] = useState('');
  const [vehicleImage, setVehicleImage] = useState(null);
  const [terms, setTerms] = useState(false);
  const [oldVehicle, setOldVehicle] = useState(null);

  const years = Array.from({ length: 121 }, (_, i) => ({
    label: 2023 - i,
    value: 2023 - i,
  }));
  const months = Array.from({ length: 12 }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));
  const [daysInMonth, setDaysInMonth] = useState(
    Array.from({ length: 31 }, (_, i) => ({ label: i + 1, value: i + 1 }))
  );

  useEffect(() => {
    if (month && year) {
      const newDaysInMonth = new Date(year, month, 0).getDate();
      const days = Array.from({ length: newDaysInMonth }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      }));
      setDaysInMonth(days);

      if (day > newDaysInMonth) {
        setDay(days[days.length - 1].value);
      }
    }
  }, [month, year, day]);
  const saveVehicle = () => {
    let postData = {
      api_token: getCookie("token"),
      type_id: 1,
      make_name: selectedMaker,
      model_name: selectedModal,
      color_name: selectedColor,
      group_id: 2,
      year: year,
      engine_type: engineType,
      plate: plate,
      horse_power: horsePower,
      doors: doors,
      luggage: luggage,
      seaters: seaters,
      aircondition: aircondition,
      transmission_type: transmissionType,
      vin: vin,
      license_plate: licencePlate,
      mileage: mileage,
      int_mileage: initMileage,
    }
    console.log(postData)
    if (!checkIfAllFieldsFilled()) return;
    getBase64(vehicleImage).then((res) => {
        postData.vehicle_image = res;
        if (isEditMode) {
            postData.id = id;
          vehicleUpdateAPI(dispatch, postData).then((res) => {
            console.log(res)
            toast("Vehicle updated successfully")
            navigate("/manage_vehicles")
          }).catch((error) => {
            console.log(error)
          })
        } else {
          createVehicleAPI(dispatch, postData).then((res) => {
            console.log(res)
            toast("Vehicle added successfully")
            navigate("/manage_vehicles")
          }).catch((error) => {
            console.log(error)
          })
        }
    }).catch((error) => {
      toast("Error in uploading file")
    })
  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (file === null || file === undefined) {
        reject('File is null');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }
    });
  }
  const checkIfAllFieldsFilled = () => {
    if (!terms) {
      toast("Please accept terms and conditions");
      return false;
    }
    if (!selectedMaker) {
      toast("Please select vehicle maker");
      return false;
    }
    if (!selectedModal) {
      toast("Please select vehicle model");
      return false;
    }
    if (!selectedColor) {
      toast("Please select vehicle color");
      return false;
    }
    if (!engineType) {
      toast("Please select engine type");
      return false;
    }
    if (!plate) {
      toast("Please enter plate");
      return false;
    }
    if (!horsePower) {
      toast("Please enter horse power");
      return false;
    }
    if (!doors) {
      toast("Please enter doors");
      return false;
    }
    if (!luggage) {
      toast("Please enter luggage");
      return false;
    }
    if (!seaters) {
      toast("Please enter seaters");
      return false;
    }
    if (!aircondition) {
      toast("Please select aircondition");
      return false;
    }
    if (!transmissionType) {
      toast("Please select transmission type");
      return false;
    }
    if (!vin) {
      toast("Please enter vin");
      return false;
    }
    if (!licencePlate) {
      toast("Please enter licence plate");
      return false;
    }
    if (!mileage) {
      toast("Please enter mileage");
      return false;
    }
    if (!initMileage) {
      toast("Please enter initial mileage");
      return false;
    }
    if (!vehicleImage) {
      toast("Please select vehicle image");
      return false;
    }
    return true;
  }
  const checkingSomething = () => {
    getVehicleVariables(dispatch).then((res) => {
      setVariables(res);
    }).catch((error) => {
      console.log(error);
    });
  }
  const isEditMode = () => {
    return id && id > 0;
  }
  useEffect(() => {
    console.log(oldVehicle)
    if (oldVehicle !== null && oldVehicle.id > 0) {
        setSelectedMaker(oldVehicle.make_name);
        setSelectedModal(oldVehicle.model_name);
        setSelectedColor(oldVehicle.color_name);
        setEngineType(oldVehicle.engine_type);
        setPlate(oldVehicle.license_plate);
        setHorsePower(oldVehicle.horse_power);
        setDoors(oldVehicle.doors);
        setLuggage(oldVehicle.luggage);
        setSeaters(oldVehicle.seaters);
        setAircondition(oldVehicle.aircondition);
        setTransmissionType(oldVehicle.transmission_type);
        setVin(oldVehicle.vin);
        setLicencePlate(oldVehicle.license_plate);
        setMileage(oldVehicle.mileage);
        setInitMileage(oldVehicle.int_mileage);
    }
  }, [oldVehicle]);
  useEffect(() => {
    console.log(id)
    checkingSomething();
    if (isEditMode) {
      console.log('++++++++++++')
      let postData = {
        api_token: getCookie("token"),
        id: id
      }
      getSingleVehicleAPI(dispatch, postData).then((res) => {
        console.log(res)
        setOldVehicle(res);
      }).catch((error) => {
        console.log(error)
      })
    }
  }, []);
  return (
    <>
      <div className="flex flex-col w-full gap-2 py-6">
        <p className="text-xl font-bold">{isEditMode ? 'Edit' : 'Add' } Vehicle</p>
        <p className="text-sm font-text">Dashboard - {isEditMode ? 'edit' : 'add' } vehicle</p>
      </div>
      <Card className="flex flex-col w-full p-6 md:w-2/3">
        <div className="grid grid-cols-3 sm:grid-cols-10 gap-6">
          <div className="col-span-3 sm:col-span-4">
            <FormControl fullWidth size="small">
              <InputLabel>Vehicle make</InputLabel>
              <Select
                  label="Vehicle make"
                  value={selectedMaker}
                  onChange={(e) => setSelectedMaker(e.target.value)}
              >
                {variables.makers.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <FormControl fullWidth size="small">
              <InputLabel>Vehicle model</InputLabel>
              <Select label="Vehicle model"
                      value={selectedModal}
                      onChange={(e) => setSelectedModal(e.target.value)}>
                {variables.models.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-2">
            <FormControl fullWidth size="small">
              <InputLabel>Color</InputLabel>
              <Select label="Color"
                      value={selectedColor}
                      onChange={(e) => setSelectedColor(e.target.value)}>
                {variables.colors.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Licence plate number"
                variant="outlined"
                size="small"
                value={licencePlate}
                onChange={(e) => setLicencePlate(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Horse power"
                variant="outlined"
                size="small"
                value={horsePower}
                onChange={(e) => setHorsePower(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-2">
            <FormControl fullWidth size="small">
              <InputLabel>Engine</InputLabel>
              <Select label="Engine"
                      value={engineType}
                      onChange={(e) => setEngineType(e.target.value)}>
                <MenuItem value="Petrol">Petrol</MenuItem>
                <MenuItem value="Diesel">Diesel</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Plate Number"
                variant="outlined"
                size="small"
                value={plate}
                onChange={(e) => setPlate(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Doors"
                variant="outlined"
                size="small"
                value={doors}
                onChange={(e) => setDoors(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-2">
            <FormControl fullWidth size="small">
              <InputLabel>Aircondition</InputLabel>
              <Select label="Aircondition"
                      value={aircondition}
                      onChange={(e) => setAircondition(e.target.value)}>
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <FormControl fullWidth size="small">
              <InputLabel>Transmission type</InputLabel>
              <Select label="Transmission type"
                      value={transmissionType}
                      onChange={(e) => setTransmissionType(e.target.value)}
              >
                <MenuItem value="Automatic">Automatic</MenuItem>
                <MenuItem value="Manual">Manual</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Luggage"
                variant="outlined"
                size="small"
                value={luggage}
                onChange={(e) => setLuggage(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-2">
            <FormControl fullWidth size="small">
              <InputLabel>Year</InputLabel>
              <Select
                  label="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
              >
                {years?.map((item) => (
                    <MenuItem value={item.value}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Seaters"
                variant="outlined"
                size="small"
                value={seaters}
                onChange={(e) => setSeaters(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Vin"
                variant="outlined"
                size="small"
                value={vin}
                onChange={(e) => setVin(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Mileage"
                variant="outlined"
                size="small"
                value={mileage}
                onChange={(e) => setMileage(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-4">
            <TextField
                fullWidth
                label="Initial Mileage"
                variant="outlined"
                size="small"
                value={initMileage}
                onChange={(e) => setInitMileage(e.target.value)}
            />
          </div>
          <div className="col-span-3 sm:col-span-8">
            <TextField
                fullWidth
                // label="Select Vehicle Image"
                variant="outlined"
                size="small"
                type="file"
                onChange={(e) => {
                  setVehicleImage(e.target.files[0]);
                }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between w-full mt-8">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
              />}
              label={
                <span className="text-sm">
                  I have read and accept the Terms & Conditions YOKS RENT A CAR
                  third party fleet management services.
                </span>
              }
            />
          </FormGroup>
          <div
              className="w-full sm:w-auto mt-4 sm:mt-0 flex items-center justify-center">
              <RButton
                  isradius={true}
                  style={{ backgroundColor: "rgba(237,139,0,0.9)" }}
                  onClick={saveVehicle}
              >
                <span className="flex items-center justify-center w-full gap-2 px-4 text-base font-normal">
                  Save
                  <BsArrowUpRight className="font-bold" />
                </span>
            </RButton>
          </div>
        </div>
      </Card>
    </>
  );
};

export default NewVehicle;
