import { FaGooglePlay } from "react-icons/fa";

const GooglePlayButton = () => {
  return (
    <div className="flex items-center w-[180px] py-3 bg-black divide-x divide-white/60 cursor-pointer select-none rounded-xl divide-solid">
      <div className="text-2xl px-3 text-white">
        <FaGooglePlay />
      </div>
      <div className="flex flex-col px-3 text-white leading-0">
        <p className="text-xs">Download on the</p>
        <p className="text-sm">Google Store</p>
      </div>
    </div>
  );
};

export default GooglePlayButton;
