import { Card } from "@mui/material";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { getCookie } from "../../utils/constants";
import {
  cancelListAPI,
  getDashboardAPI,
  vehicleIncomeAndExpenseAPI,
} from "../../redux/actions/myaccountAction";
import { useDispatch } from "react-redux";

const IncomeTransaction = () => {
  const [transactions, setTransaction] = useState([]);
  const dispatch = useDispatch();
  const [metaDetails, setMetaDetails] = useState({});

  const columns = [
    {
      title: "Vehicle",
      dataIndex: "vehicle",
    },
    {
      title: "Income Type",
      dataIndex: "income_type",
    },
    {
      title: "Tax Amount GHS",
      dataIndex: "tax_amount",
    },
    {
      title: "Amount GHS",
      dataIndex: "amount",
    },
    {
      title: "Initial Mileage (KM)",
      dataIndex: "initial_mileage",
    },
    {
      title: "End Mileage (KM)",
      dataIndex: "end_mileage",
    },
    ,
    {
      title: "Mileage (KM)",
      dataIndex: "mileage",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    let postData = {
      api_token: getCookie("token"),
    };
    vehicleIncomeAndExpenseAPI(dispatch, postData, "income")
      .then((res) => {
        console.log(res);
        setTransaction(res.data);
        setMetaDetails(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyIncomeData = () => {
    const incomeData = [];
    transactions.forEach((item, index) => {
      incomeData.push({
        key: index,
        vehicle:
          item.vehicle.make_name +
          " " +
          item.vehicle.model_name +
          " " +
          item.vehicle.license_plate,
        income_type: "Booking",
        tax_amount: item.tax_charge_rs,
        amount: item.thirdparty_amount,
        date: item.date,
        initial_mileage:item.initial_mileage,
        end_mileage:item.end_mileage,
        mileage:item.end_mileage-item.initial_mileage
      });
    });

    // Sorting data in descending order based on key
    incomeData.sort((a, b) => {
      return b.key - a.key;
    });

    return incomeData;
  };

  return (
    <>
      <div className="w-full py-6 flex flex-col gap-2">
        <p className="font-bold text-xl">Income Transactions</p>
        <p className="font-text text-sm">Dashboard - Income Transactions</p>
      </div>
      <Card className="w-full p-6 flex flex-col gap-6">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-xl">Vehicle list</p>
        </div>
        <div className="w-full">
          <div className="overflow-x-auto max-w-full">
            <Table columns={columns} dataSource={getMyIncomeData()} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default IncomeTransaction;
