import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import RButton from "./RButton";
import { motion, useAnimation } from "framer-motion";

export default function HeroSlider() {
  const sliderRef = useRef(null);
  const navigation = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const controls = useAnimation();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (current) => setCurrentSlide(current),
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    fade: true,
  };

  const slides = [
    {
      image: "yoks.jpg",
      text1: "Welcome to Ghana's most reliable choice for car rental services",
    },
    {
      image: "slider2.jpg",
      text1:
        "Explore how to earn passive income with your own vehicles at YOKS",
      text2: "YOKS Self Drive Services",
    },
    {
      image: "slider3.jpg",
      text1: "Contact YOKS for business transportation solutions",
      text2: "YOKS Shuttle Drive Services",
    },
  ];

  // Trigger the animation whenever the slide changes
  useEffect(() => {
    controls.start({
      scale: [1, 1.1, 1.2],
      opacity: [1, 1],
      transition: { duration: 10, ease: "easeInOut" },
    });
  }, [currentSlide, controls]);

  return (
    <div className="relative pb-24">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="relative w-full h-screen">
            <motion.img
              src={`image/home/${slide.image}`}
              alt={`slide${index + 1}`}
              className="w-full h-full object-cover brightness-50"
              initial={{ scale: 1, opacity: 1 }}
              animate={controls}
            />
            <div className="absolute top-[40%] left-[50%] transform -translate-x-1/2 text-white text-center">
              <motion.h2
                className="text-lg md:text-3xl lg:text-5xl xl:text-5xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {slide.text1}
              </motion.h2>

              <div className="hidden md:block absolute left-1/2 top-24 transform -translate-x-1/2 bottom-8 p-4 rounded-md z-20">
                <div className="flex items-start pt-8 justify-start w-full h-full">
                  <RButton
                    isradius={true}
                    onClick={() => navigation("/ourfleet")}
                  >
                    <span className="flex items-center gap-4 px-10">
                      <h5>Find The Best Car To Rent</h5>
                    </span>
                  </RButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Bullet indicators */}
      <div className="absolute top-1/2 right-8 transform -translate-y-1/2 flex flex-col space-y-2">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${
              currentSlide === index ? "bg-white" : "bg-gray-400"
            }`}
            onClick={() => sliderRef.current.slickGoTo(index)}
          />
        ))}
      </div>
    </div>
  );
}
