/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleAllList,
  getVehicleAllListByFCM,
  getVehicleByType,
  getVehiclesTypes,
  setVehicleDetails,
  setVehicleID,
} from "../../redux/actions/bookingAction";
import VehicleCarItem from "../../components/VehicleCarItem";
import styled from "styled-components";
import Select from "react-select";
import moment from "moment/moment";

const Vehicle = ({ onStep, nextPageClicked, setNextPageClicked }) => {
  const MSelect = styled(Select)`
    height: 53px;
    width: 100%;
    & > div:first-of-type {
      height: 100%;
      width: 100%;
    }
  `;

  useEffect(() => {
    loadMoreVehicles(nextPageClicked)
  }, [nextPageClicked]);
  const dispatch = useDispatch();
  const book_state = useSelector((state) => state.book);
  const vehicleTypes = useSelector((state) => state.globalData?.vehicleTypes);
  const vehiclesByType = useSelector(
    (state) => state.globalData?.vehiclesByType
  );
  const vehiclesByTypePagination = useSelector(
    (state) => state.globalData?.vehiclesByTypePagination
  );
  const [selectedType, setSelectedType] = useState(null);
  const onSelectVehicle = (vehicle_id, details) => {
    onStep(2);
    setVehicleID(dispatch, vehicle_id);
    setVehicleDetails(dispatch, details);
  };

  const getData = () => {
    let postData;
    if (book_state?.rtype === 2)
      postData = {
        stype: book_state?.serviceType.value,
        startdate: book_state?.startdate.format("YYYY-MM-DD"),
        enddate: book_state?.enddate.format("YYYY-MM-DD"),
        starttime: book_state?.starttime.format("HH:mm:ss"),
        endtime: book_state?.endtime.format("HH:mm:ss"),
      };
    else
      postData = {
        hours: book_state?.serviceHourly.value,
      };
    console.log(postData, book_state?.rtype);
    getVehicleAllList(dispatch, postData, book_state?.rtype);
    getVehicleAllListByFCM(dispatch, postData, book_state?.rtype);
    getVehiclesTypes(dispatch);
  };

  useEffect(() => {
    console.log(book_state);
    console.log(book_state);
    getData();
  }, []);
  useEffect(() => {
    console.log(vehicleTypes);
    if (
      vehicleTypes === null ||
      vehicleTypes === undefined ||
      vehicleTypes?.length === 0
    ) {
      getData();
    }
  }, [vehicleTypes]);
  const onVehicleSelected = (vehicle) => {
    console.log(vehicle, ',,,,,,,,,,');
    setSelectedType(vehicle);
    getVehicleList(vehicle.value);
  };
  const retuningWithValues = () => {
    let data = [];
    if (vehicleTypes?.length > 0) {
      data = vehicleTypes.map((item) => {
        return {
          value: item.id,
          label: item.displayname,
          raw: item,
        };
      });
    }
    return data;
  };
  const loadMoreVehicles = (id) => {
    console.log(id + '-------------------');
    console.log(selectedType)
    getVehicleList(selectedType?.value, id);
  }
  const getVehicleList = (id, newPage = 1) => {
    console.log(id);
    console.log(book_state);
    const postData = {
      // type_id: id,
      branch_id: book_state?.selectedBranch?.value,
    };
    console.log(postData);
    getVehicleByType(dispatch, postData, newPage);
    // let page = newPage;
    // let loadMore = true
    // if (vehiclesByTypePagination?.current_page) {
    //   // current_page:25
    //   // last_page:5
    //   // next_page_url:null
    //   // per_page:5
    //   // prev_page_url:
    //   //     https://fleet.yoksghana.com/api/v1/fetch-vehicle-list-by-type?page=24"total:25
    //   // calculate current is next page vaialabe
    //     if (vehiclesByTypePagination.current_page < vehiclesByTypePagination.last_page) {
    //         page = vehiclesByTypePagination.current_page + 1
    //       } else {
    //         loadMore = false
    //       }
    //     }
    // if (loadMore) {
    // }
  };
  useEffect(() => {
    console.log(selectedType);
  }, [selectedType]);
  useEffect(() => {
    console.log(vehiclesByType);
  }, [vehiclesByType]);
  useEffect(() => {
    if (selectedType === null && vehicleTypes?.length > 0) {
      onVehicleSelected(retuningWithValues()[0]);
    }
  }, [selectedType, vehicleTypes]);
  return (
    <>
      {/*<p className="font-bold text-3xl ">Select Your Vehicle Type</p>*/}
      {/*{vehicleTypes?.length > 0 && (*/}
      {/*  <MSelect*/}
      {/*    options={retuningWithValues()}*/}
      {/*    onChange={(e) => onVehicleSelected(e)}*/}
      {/*    defaultValue={retuningWithValues()[0]}*/}
      {/*    value={selectedType}*/}
      {/*  />*/}
      {/*)}*/}

      <div className="w-full mt-4">
        {vehiclesByType?.map((item, index) => {
          return (
            <VehicleCarItem
              key={index}
              param={item}
              // passengers={item.vehicles[0]?.seaters}
              passengers={item?.seaters}
              luggage={item.luggage}
              transmission_type={item.transmission_type}
              airconditioning={item.airconditioning}
              doors={item.doors}
              onSelect={onSelectVehicle}
            />
          );
        })}
      </div>
    </>
  );
};

export default Vehicle;
