import React, { useRef } from "react";
import Slider from "react-slick";
import { IoArrowBackCircleOutline } from "react-icons/io5"; // Make sure to import from 'react-icons/io5' for the latest version
import "./index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IoIosArrowRoundBack, IoIosArrowRoundForward} from "react-icons/io";

const Carousel = ({ children, breakPoints, xbuttonMode = false }) => {
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: xbuttonMode ? 3 : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakPoints? 1280 : 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: breakPoints? 1024 : 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints? 600 : 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`${xbuttonMode ? 'flex gap-3 items-center justify-between' : ''}`}>
      {
        xbuttonMode && <button
          className="button text-black/40 text-3xl hover:bg-gray-200 border-[1px] w-12 h-12 rounded-full hidden sm:flex items-center justify-center border-gray-300 hover:border-gray-500"
          onClick={previousSlide}>
        <IoIosArrowRoundBack/>
      </button>
      }
      <Slider ref={sliderRef} {...settings} className={`${xbuttonMode ? 'w-full sm:w-[calc(100%-120px)]' : ''}`}>
        {children}
      </Slider>
      {
        xbuttonMode && <button
          className="button text-black/40 text-3xl hover:bg-gray-200 border-[1px] w-12 h-12 rounded-full hidden sm:flex items-center justify-center border-gray-300 hover:border-gray-500"
          onClick={nextSlide}>
        <IoIosArrowRoundForward/>
      </button>
      }
      {
        !xbuttonMode && <div className="mt-12 flex-row justify-start my-12 hidden sm:flex">
          <button
              className="button text-[#ED8B00] text-3xl mx-4 hover:bg-gray-200 border-[1px] w-16 h-16 rounded-full flex items-center justify-center border-gray-300 hover:border-gray-500"
              onClick={previousSlide}>
            <IoIosArrowRoundBack/>
          </button>
          <button
              className="button text-[#ED8B00] text-3xl mx-4 hover:bg-gray-200 border-[1px] w-16 h-16 rounded-full flex items-center justify-center border-gray-300 hover:border-gray-500"
              onClick={nextSlide}>
            <IoIosArrowRoundForward/>
          </button>
        </div>
      }
    </div>
  );
};

export default Carousel;
