const BottomBar = () => {
  return (
    <div className="w-full">
      <img
        className="w-full"
        alt=""
        src="image/home/footer_illustration.svg"
      />
    </div>
  )
}

export default BottomBar;