import React, { useState } from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment from 'moment';
import {BsCalendar4Event} from "react-icons/bs";
const MDatePicker = styled(DatePicker)`
  & > .ant-picker-input {
    display: none;
  }
`;

const ButtonDatePicker = ({ onDateChange, icon, dvalue, startDate, value, title }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (open) => {
    setOpen(open);
  };

  const handleChange = (value) => {
    onDateChange(value);
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };
  const addingOneDay = (date) => {
    return moment(date).add(1, 'days');
  }

  return (

      <div className="rounded-lg p-2 px-4 pr-6 flex items-center justify-start mt-2 border border-r-gray-300">
          <div className="bg-white rounded-md p-2">
            <div
                onClick={handleClick}
                className="cursor-pointer rounded-sm w-8 h-8 flex items-center justify-center relative"
            >
              <img src="image/home/datepicker.svg" />
              <MDatePicker
                open={open}
                onOpenChange={handleOpenChange}
                onChange={handleChange}
                bordered={false}
                suffixIcon={null}
                // value={dvalue}
                disabledDate={(current) => {
                    let returning = false;
                    if (startDate) {
                        returning = current && current < moment(addingOneDay(startDate?.format('YYYY-MM-DD'))).startOf('day');
                    } else {
                        returning = current && current < moment().startOf('day');
                    }
                    // disable all dates before dvalue
                    // if (dvalue) {
                    //     // console.log(moment(dvalue).startOf('day'))
                    //     returning = current < moment(dvalue?.format('YYYY-MM-DD')).startOf('day');
                    // }
                    return returning;
                }
                }
              />
            </div>
          </div>
          <div className="flex-1 h-full flex flex-col justify-between">
              <p className="text-[#333]">{title}</p>
              <p className="text-[var(--text-color)] font-bold" onClick={handleClick}>
                  {value?.format("ddd, MMM DD, YYYY")}
              </p>
          </div>
      </div>
  )
};

export default ButtonDatePicker;
