import React, {useEffect} from 'react'
import {Modal, Tabs} from "antd";
import styled from "styled-components";
const BModal = styled(Modal)`
  & > .ant-modal-content {
    padding: 0;
    button {
      color: white;
      top: 0px;
      right: 0px;
    }
    .ant-modal-header {
      background-color: #024273;
      padding: 15px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-block: 20px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;
const VehicleDetailsModal = ({visible, setVisible, item}) => {
    const [realKeys, setRealKeys] = React.useState([])
    const handleCancel = () => {
        setVisible(false)
    }
    const keys = [
        {
            key: 'aircondition',
            value: 'Air Condition'
        },
        {
            key: 'color_name',
            value: 'Color'
        },
        {
            key: 'doors',
            value: 'doors'
        },
        {
            key: 'engine_type',
            value: 'engine_type'
        },
        {
            key: 'group_id',
            value: 'group_id'
        },
        {
            key: 'horse_power',
            value: 'horse_power'
        },
        {
            key: 'in_service',
            value: 'in_service'
        },
        {
            key: 'int_mileage',
            value: 'int_mileage'
        },
        {
            key: 'license_plate',
            value: 'license_plate'
        },
        {
            key: 'luggage',
            value: 'luggage'
        },
        {
            key: 'Make Name',
            value: 'make_name'
        },
        {
            key: 'mileage',
            value: 'mileage'
        },
        {
            key: 'model_name',
            value: 'model_name'
        },
        {
            key: 'seaters',
            value: 'seaters'
        },
        {
            key: 'vin',
            value: 'vin'
        },
        {
            key: 'year',
            value: 'year'
        }
    ]
    useEffect(() => {
        if (item?.id !== undefined && item?.id !== '' && item?.id !== null && item?.id !==0) {
            setRealKeys(keys)
        } else {
            setRealKeys([])
        }
        console.log(item);
    }, [item]);
    return (
        <BModal
            title={
                <div className="flex items-center gap-5">
                    <p className="font-bold text-xl text-white">Vehicle Details</p>
                </div>
            }
            width={"500px"}
            open={visible}
            onCancel={handleCancel}
        >
            <div className="grid w-full grid-cols-1 bg-white pl-6 pr-6">
                {
                    realKeys.map((i, index) => {
                        return (
                            <div key={index} className="col-span-1 border border-blue-900 border-t-0 border-l-0 border-r-0 border-b-1">
                                <div className="flex justify-between">
                                    <p className="text-md font-bold">
                                        {i.value}
                                    </p>
                                    <p className="text-xl font-bold text-right">
                                        {item[i.key]}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </BModal>
    )
}

export default VehicleDetailsModal