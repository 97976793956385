import { Link } from "react-router-dom";
import { SlLocationPin } from "react-icons/sl";
import { LuPhone } from "react-icons/lu";
import { TbClockHour9 } from "react-icons/tb";
import { GiWorld } from "react-icons/gi";
import RButton from "../components/RButton";
import AppleButton from "../components/AppleButton";
import GooglePlayButton from "../components/GooglePlayButton";
import FooterNav from "../pages/Components/FooterNav";
import FooterContent from "../pages/Components/FooterContent";
import FooterEnd from "../pages/Components/FooterEnd";
import Images from "../utils/ImageConstant";

const Footer = () => {
  return (
    <>
      <>
        <div
          className=" bg-cover "
          style={{
            backgroundImage: `url(${Images.FooterBG})`,
            backgroundPositionX: "center",
          }}
        >
          <FooterNav />
          <div className="flex justify-center">
            <div className="w-[80vw] border-t border-gray-100"></div>
          </div>

          <FooterContent />
          <div className="flex justify-center">
            <div className="w-[80vw] border-t border-gray-100"></div>
          </div>

          <FooterEnd />
        </div>
      </>
    </>
  );
};

export default Footer;
