import BreadCrumb from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import BottomBar from "../../components/BottomBar";

const ServiceSelfdrive = () => {
  return (
    <>
      <div className="w-full">
        <BreadCrumb title="Self Drive Options">
          <Link to="/">Home</Link> - <Link to="#">Services</Link>
        </BreadCrumb>
      </div>
      <div className="relative w-full">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="max-w-[1280px] w-[90%] h-full relative m-auto"></div>
        </div>
        <img
          src="image/services/selfdrive_banner.png"
          className="w-full"
          alt=""
        />
      </div>
      <div className="w-full py-8">
        <div className="max-w-[1280px] w-[90%] m-auto">
          <h1 className="text-[44px] font-semibold">Self-Drive options</h1>
          <p className="mt-8 text-base">
            Another popular service is our self-drive car rental option. Clients
            who are adventurous and are familiar with the driving conditions and
            terrain of Accra and Ghana get to drive themselves with any vehicle
            of their choice within Ghana. Terms & Conditions apply.
          </p>
          <div className="flex flex-col gap-4 mt-8 text-base">
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>100% Luxurious Fleet</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>All our Fleet Are Fully Valeted & Serviced</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>A Safe & Secure Journey</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>Comfortable and Enjoyable</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>Clean, Polite & knowledgeable</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-8">
        <div className="max-w-[1280px] w-[90%] m-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="cols-span-1">
              <img
                src="image/services/selfdrive_thumbnail1.svg"
                className="w-full"
                alt=""
              />
            </div>
            <div className="cols-span-1 flex flex-col justify-center items-center">
              <h1 className="text-[44px] font-semibold">
                Enjoy the ride with your love one’s
              </h1>
              <p className="text-[var(--text-color)] font-text">
                Choose from our wide variety of rides and take that road trip
                you have always been wanting to take with your loved ones.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse mt-6 md:mt-0 md:grid grid-cols-2 gap-16">
            <div className="cols-span-1 flex flex-col justify-center items-center">
              <h1 className="text-[44px] font-semibold">
                Want a ride around the city?
              </h1>
              <p className="text-[var(--text-color)] font-text">
                We have you covered! We have all the vehicle types you need to
                experience Ghana.
              </p>
            </div>
            <div className="cols-span-1">
              <img
                src="image/services/selfdrive_thumbnail2.svg"
                className="w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default ServiceSelfdrive;
