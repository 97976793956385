import { useEffect, useState, useMemo } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import { toast } from "react-toastify";
import {toast_options, isEmail, getCookie} from "../../utils/constants";

import countryList from "react-select-country-list";
import { saveProfile } from "../../redux/actions/myaccountAction";
import { SET_LOADING } from "../../redux/type";
import {getInfoByToken} from "../../redux/actions/authAction";
import {useNavigate} from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2023);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("Female");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const userdata = useSelector((state) => state.auth.user);
  const years = Array.from({ length: 121 }, (_, i) => ({
    label: 2023 - i,
    value: 2023 - i,
  }));
  const months = Array.from({ length: 12 }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));
  const [daysInMonth, setDaysInMonth] = useState(
    Array.from({ length: 31 }, (_, i) => ({ label: i + 1, value: i + 1 }))
  );

  const countries = useMemo(() => countryList().getData(), []);

  useEffect(() => initialize(userdata), [userdata]);

  useEffect(() => {
    if (month && year) {
      const newDaysInMonth = new Date(year, month, 0).getDate();
      const days = Array.from({ length: newDaysInMonth }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      }));
      setDaysInMonth(days);

      if (day > newDaysInMonth) {
        setDay(days[days.length - 1].value);
      }
    }
  }, [month, year, day]);

  const initialize = (info) => {
    let newPhone = info?.phone?.replace(/\+/g, "");
    let newGender = 'Male'
    if (info) {
      if (info?.gender === 'M') {
        newGender = 'Male'
      } else {
        newGender = 'Female'
      }
    }
    setName(info?.name);
    setGender(newGender);
    setDay(parseInt(info?.dob?.split("-")[2]));
    setMonth(parseInt(info?.dob?.split("-")[1]));
    setYear(parseInt(info?.dob?.split("-")[0]));
    setEmail(info?.email);
    setPhone(newPhone);
    setCity(info?.city);
    setCountry(info?.country);
  };

  const updateProfile = () => {
    if (name === "") {
      toast.warn("Incorrect your name", toast_options);
      return;
    }

    if (!(day && month && year)) {
      toast.warn("Date of birth not selected", toast_options);
      return;
    }

    if (!isEmail(email)) {
      toast.warn("Incorrect your email format", toast_options);
      return;
    }

    if (phone.length < 1) {
      toast.warn("Please input your phonenumber", toast_options);
      return;
    }

    if (city === "") {
      toast.warn("Incorrect your city", toast_options);
      return;
    }
    let postData = {
      gender,
      dob: `${year}-${month}-${day}`,
      city,
      country,
      phone: `+${phone}`,
      email,
      api_token: getCookie("token"),
      last_name: ' '
    };
    // check if name has 2 word then split it and save it as first name and last name
    if (name.split(" ").length === 2) {
      let nameArray = name.split(" ");
      postData.name = nameArray[0];
      postData.last_name = nameArray[1];
    } else if(name.split(" ").length > 2) {
    //   now just split from the last space
        let nameArray = name.split(" ");
        let lastName = nameArray[nameArray.length - 1]
        let firstName = nameArray.slice(0, nameArray.length - 1).join(" ")
        postData.name = firstName;
        postData.last_name = lastName;
    } else {
        postData.name = name;
        postData.last_name = name
    }
    dispatch({ type: SET_LOADING, payload: true });
    saveProfile(dispatch, postData, navigate);
  };
  return (
    <>
      {userdata?.customer_type === "I" ? (
        <div className="flex flex-col w-full gap-2 py-6">
          <p className="text-xl font-bold">Edit Profile</p>
          <p className="text-sm font-text">Dashboard - Edit Profile</p>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-2 py-6">
          <p className="text-xl font-bold">Edit Company Information</p>
          <p className="text-sm font-text">
            Dashboard - Edit Company Information
          </p>
        </div>
      )}
      <div className="grid w-full grid-cols-1 lg:grid-cols-3">
        <div className="col-span-2">
          <div className="w-full px-8 py-12 bg-white rounded-sm shadow-sm">
            <div className="grid grid-cols-1 gap-6">
              <div className="col-span-1">
                <TextField
                  fullWidth
                  label={`${
                    userdata?.customer_type === "I" ? "Name" : "Company Name"
                  }`}
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {userdata?.customer_type === "I" ? (
                <>
                  <div className="col-span-1">
                    <FormControl fullWidth size="small">
                      <InputLabel>Gender</InputLabel>
                      <Select
                        label="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex col-span-1 gap-4 flex-nowrap">
                    <FormControl fullWidth size="small">
                      <InputLabel>Day</InputLabel>
                      <Select
                        label="Day"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                      >
                        {daysInMonth?.map((item) => (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                      <InputLabel>Month</InputLabel>
                      <Select
                        label="Month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      >
                        {months?.map((item) => (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                      <InputLabel>Year</InputLabel>
                      <Select
                        label="Year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        {years?.map((item) => (
                          <MenuItem value={item.value}>{item.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="col-span-1">
                <TextField
                  fullWidth
                  label={`${
                    userdata?.customer_type === "C" ? "Company" : ""
                  } Email`}
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <PhoneInput
                  value={phone}
                  onChange={setPhone}
                  country={"gn"}
                  placeholder="Enter Phone Number"
                  className="h-[40px]"
                  inputClass="!h-[40px] !w-full"
                />
              </div>
              <div className="col-span-1">
                <TextField
                  fullWidth
                  label="City"
                  variant="outlined"
                  size="small"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <FormControl fullWidth size="small">
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries?.map((item) => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-span-1">
                <RButton
                  isradius={true}
                  style={{ backgroundColor: "rgba(237,139,0,0.9)" }}
                  onClick={updateProfile}
                >
                  <span className="flex items-center justify-center w-full gap-2 px-10 font-normal">
                    Update{" "}
                    {userdata?.customer_type === "I"
                      ? "Profile"
                      : "Information"}
                    <BsArrowUpRight className="font-bold" />
                  </span>
                </RButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
