import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import ValuesCard from "../../components/ValuesCard";
import FleetCard from "../../components/FleetCard";
import SlideCard from "../../components/SlideCard";
import "aos/dist/aos.css";
import axios from "axios";
import BottomBar from "../../components/BottomBar";
import BookingModal from "../../components/BookingModal";
import { GET_CAR_LIST } from "../../redux/type";
import { toast } from "react-toastify";
import { stringConstant, toast_options } from "../../utils/constants";
import { fms_fleet } from "../../utils/webAPIs";
import { FMS_BACKEND_URL } from "../../utils/config";
import { useSelector } from "react-redux";
import { FMS_MEDIA } from "../../utils/config";
import Hero from "../Components/Hero";
const Home = () => {
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const [carList, setCarList] = useState([]);
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    AOS.init();
    getFleetData();
  }, []);

  const getFleetData = () => {
    axios
      .get(`${FMS_BACKEND_URL}${fms_fleet}`)
      .then((res) => {
        // const { categories, vehicles } = res.data;
        // const result = categories.slice(0, 3).map((category) => ({
        //   ...category,
        //   vehicles: vehicles.filter(
        //     (vehicle) => vehicle.category === category.id
        //   ),
        // }));
        if (res.status === 200) {
          console.log(res);
          const { vehicleTypes, vehicles } = res.data.data;
          const result = [];
          vehicleTypes.map((category) => {
            // ...category,
            //       vehicles: vehicles.filter(
            //       (vehicle) => vehicle.type_id === category.id
            //   ),
            if (result.length >= 3) return;
            const vehicle = vehicles.filter(
              (vehicle) => vehicle.type_id === category.id
            );
            if (vehicle.length > 0) {
              result.push({
                ...category,
                vehicles: vehicle,
              });
            }
          });
          console.log(result);
          setCarList(result);
        } else {
          toast.error(stringConstant.failed_register, toast_options);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="relative w-full ">
        <div className="absolute top-0 z-10 w-full h-full bg-black/30">
          <div className="max-w-[1280px] w-[90%] h-full m-auto grid grid-rows-2 relative">
            <div className="row-span-2 md:row-span-1 flex md:block items-center my-auto">
              <h2 className="text-white ">Where Would You Like To Go?</h2>
              <p
                className="text-white font-bold text-[26px] md:text-[38px] lg:text-[46px] xl:text-[52px] w-full md:w-2/3"
                data-aos="fade-up"
              >
                A New Class Of <br />
                Chauffeur Services{" "}
              </p>
              <div className="row-span-1 hidden md:block">
                <div className="flex items-start pt-8 justify-start w-full h-full">
                  <RButton
                    isradius={true}
                    data-aos="fade-down"
                    onClick={() => setBookingModalVisible(true)}
                  >
                    <span className="flex items-center gap-4 px-10">
                      Find The Best Car To Rent
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_82_83)">
                          <path
                            d="M15.3033 13.7795C15.0735 13.7795 14.865 13.6864 14.7142 13.5355C14.5633 13.3847 14.4702 13.1761 14.4702 12.9463L14.4702 6.70846L5.28609 15.8925C4.96023 16.2184 4.43344 16.2184 4.10758 15.8925C3.78172 15.5667 3.78172 15.0399 4.10758 14.714L13.2917 5.52995L7.05377 5.52992C6.59297 5.52992 6.2206 5.15754 6.2206 4.69675C6.2206 4.23595 6.59306 3.86348 7.05386 3.86348L15.3034 3.86348C15.7642 3.86348 16.1366 4.23583 16.1366 4.69663L16.1366 12.9462C16.1366 13.407 15.7641 13.7795 15.3033 13.7795Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_82_83">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </RButton>
                </div>
              </div>
            </div>{" "}
            <div className="hidden md:flex md:justify-center items-end">
              <Hero />
            </div>
          </div>
        </div>
        <img src="image/home/hero.jpg" alt="home_banner" className="w-full" />
      </div>
      <div className="md:hidden">
        <Hero />
      </div>

      <div className="w-full bg-[#FDEEEC] py-8">
        <div className="max-w-[1280px] w-[90%] m-auto flex items-center flex-col gap-20">
          <h1 className="text-[50px] font-bold">Our Core Values</h1>
          <div className="flex flex-wrap justify-center w-full gap-6 md:flex-nowrap">
            <ValuesCard
              icon="safetyFirst.svg"
              title="Safety First"
              text="We make an extra effort to ensure that safety standard are at their highest peak  and adhered to at all times."
              data-aos="fade-up"
              data-aos-duration="150"
            />
            <ValuesCard
              icon="integrity.svg"
              title="Integrity"
              text="We are respectful and responsible for following through on our commitments to clients and other stakeholders."
              data-aos="fade-up"
              data-aos-duration="250"
            />
            <ValuesCard
              icon="accountability.svg"
              title="Accountability"
              text="We demand accountability in every aspect of our business from all our staff."
              data-aos="fade-up"
              data-aos-duration="350"
            />
          </div>
        </div>
      </div>
      <div className="w-full py-8">
        <div className="max-w-[1280px] w-[90%] m-auto flex flex-col gap-20">
          <div className="flex justify-between w-full">
            <h1 className="text-4xl font-bold text-[var(--text-color)]">
              Our Fleet
            </h1>
            <span className="flex items-center gap-2">
              <Link
                to={"/ourfleet"}
                className="text-base text-[var(--main-color2)] font-bold"
              >
                More Fleet
              </Link>
              <BsArrowUpRight className="text-base text-[var(--text-color)] font-bold" />
            </span>
          </div>
          <div className="flex flex-wrap justify-center w-full gap-6 xl:flex-nowrap items-stretch">
            {carList.length > 0 ? (
              carList.map((item, index) => (
                <FleetCard
                  title={item.displayname}
                  description={item.description}
                  passengers={item.vehicles[0]?.seaters}
                  // image={'https://fms.focused-solution.com/uploads/vehicles/6E534E177E.png'}
                  doors={item.vehicles[0]?.doors}
                  luggage={item.vehicles[0]?.luggage}
                  transmission_type={item.vehicles[0]?.transmission_type}
                  airconditioning={item.vehicles[0]?.aircondition}
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration="150"
                  image={`${
                    item.vehicles.length > 0
                      ? FMS_MEDIA +
                        `/uploads/vehicles/${item.vehicles[0].vehicle_image}`
                      : "image/home/fleetcar1.png"
                  }`}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <img src="image/home/service_car.svg" alt="" />
      </div>
      <div className="w-full bg-[#FDEEEC] pt-[60px] pb-7 mt-[-20px]">
        <div className="max-w-[1280px] w-[90%] m-auto flex flex-col gap-8">
          <div className="flex justify-between w-full">
            <h1 className="text-4xl font-bold text-[var(--text-color)]">
              Our Services
            </h1>
            <span className="flex items-center gap-2">
              <Link
                to="/services"
                className="text-base text-[var(--main-color2)] font-bold"
              >
                More Services
              </Link>
              <BsArrowUpRight className="text-base text-[var(--text-color)] font-bold" />
            </span>
          </div>
          <div
            className="w-full text-xl text-center md:text-3xl"
            data-aos="fade-up"
            data-aos-duration="150"
          >
            <p className="font-text">
              YOKS RENT - A - CAR&apos;s VALUE PROPOSITION is to provide
              solutions in transportation, by providing clients with the desired
              options of services and vehicles, to managing to client&apos;s
              transportation and logistics needs.
            </p>
            <p className="font-text">
              YOKS offers clients and customers a range of services cantered
              around providing transportation management solutions in the areas
              of:
            </p>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-1">
          <img src="image/home/airportpickup.png" className="w-full" alt="" />
        </div>
        <div className="col-span-1 p-24 flex justify-center flex-col">
          <h1 className="text-[44px] font-semibold">Airport Pickup</h1>
          <p className="my-8 font-text">
            Our airport shuttle service provides a convenient and stress-free
            way to get to and from the airport. We offer pick-up and drop-off
            services from all major airports to your destination
          </p>
          <RButton isradius={true}>
            <Link to="/service_detail?name=service_shuttle" className="w-full">
              <p className="flex items-center gap-4">
                <span className="text-base">More Detail</span>
                <BsArrowUpRight />
              </p>
            </Link>
          </RButton>
        </div>
      </div>
      <div
        className="w-full h-max lg:h-[275px] overflow-hidden relative"
        style={{
          backgroundImage: "url('image/home/number_back.svg')",
        }}
      >
        <div className="max-w-[1280px] w-[90%] h-full grid grid-cols-1 md:grid-cols-2 items-center justify-center m-auto py-2">
          <div
            className=" text-white flex flex-wrap gap-2 md:block text-[28px] md:text-[35px] lg:text-[40px] font-semibold col-span-1"
            data-aos="fade-right"
          >
            <p>Showcase some</p>
            <p>impressive numbers.</p>
          </div>
          <div
            className="flex items-center justify-between col-span-1"
            data-aos="fade-left"
          >
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">285</p>
              <p>vehicles</p>
            </div>
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">200,000</p>
              <p>Miles</p>
            </div>
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">13K</p>
              <p>Happy Customers</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <h1 className="text-center text-[var(--text-color)] text-[40px] mt-5 font-semibold">
          Discover Ghana with YOKS
        </h1>
        <div className="max-w-[1280px] w-[90%] grid sm:grid-cols-2 lg:grid-cols-4 gap-8 m-auto mt-6">
          <div className="col-span-1 flex flex-col gap-4 items-center">
            <img
              src="image/services/tourpackages_thumbnail1.svg"
              className="rounded-md"
              alt=""
            />
            <p className="font-text">Elmina</p>
          </div>
          <div className="col-span-1 flex flex-col gap-4 items-center">
            <img
              src="image/services/tourpackages_thumbnail2.svg"
              className="rounded-md"
              alt=""
            />
            <p className="font-text">Larabanga mosque</p>
          </div>
          <div className="col-span-1 flex flex-col gap-4 items-center">
            <img
              src="image/services/tourpackages_thumbnail3.png"
              className="rounded-md"
              alt=""
            />
            <p className="font-text">Axim</p>
          </div>
          <div className="col-span-1 flex flex-col gap-4 items-center">
            <img
              src="image/services/tourpackages_thumbnail4.png"
              className="rounded-md"
              alt=""
            />
            <p className="font-text">Aburi botanical garden</p>
          </div>
        </div>
        <img src="image/home/discover_yoks.svg" alt="" className="w-full" />
        <h1 className="text-center text-[var(--text-color)] text-[40px] mt-5 font-semibold">
          We make sure that your every trip is comfortable
        </h1>
        <div className="w-[90%] max-w-[1280px] m-auto grid grid-cols-3 mt-20 gap-20 mb-20">
          <div
            className="flex flex-col items-center justify-center col-span-1 gap-4"
            data-aos="fade-up"
            data-duration="150"
          >
            <img
              src="image/home/accountability.svg"
              alt=""
              className="h-[50px]"
            />
            <div className="text-[var(--text-color)]">
              <p>Luxury fleet</p>
              <p>Selection</p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center col-span-1 gap-4"
            data-aos="fade-up"
            data-duration="200"
          >
            <img src="image/home/phone.svg" alt="" className="h-[50px]" />
            <div className="text-[var(--text-color)]">
              <p>24/7 Order</p>
              <p>Available</p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center col-span-1 gap-4"
            data-aos="fade-up"
            data-duration="250"
          >
            <img src="image/home/driver.svg" alt="" className="h-[50px]" />
            <div className="text-[var(--text-color)]">
              <p>Professional</p>
              <p>Car Drivers</p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center col-span-1 gap-4"
            data-aos="fade-up"
            data-duration="300"
          >
            <img src="image/home/safetyFirst.svg" alt="" className="h-[50px]" />
            <div className="text-[var(--text-color)]">
              <p>Safe Drive</p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center col-span-1 gap-4"
            data-aos="fade-up"
            data-duration="350"
          >
            <img src="image/home/integrity.svg" alt="" className="h-[50px]" />
            <div className="text-[var(--text-color)]">
              <p>Competitive Price</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#FDEEEC] py-16">
        <div className="w-[90%] max-w-[1280px] m-auto grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-36">
          <div
            className="flex flex-col items-start justify-start col-span-1 gap-6"
            data-aos="fade-up"
            data-duration="200"
          >
            <p className="text-[40px] font-semibold font-text">
              Hear what our amazing customers say
            </p>
            <p>
              Feel free to leave us a review we will be glad to hear about your
              experience with us
            </p>
            <Link
              to={user ? "#" : "/signup"}
              onClick={() => {
                if (user) {
                  setBookingModalVisible(true);
                }
              }}
            >
              <RButton isradius={true}>
                <p className="flex items-center gap-4">
                  <span>Get Started</span>
                  <BsArrowUpRight />
                </p>
              </RButton>
            </Link>
          </div>
          <div
            className="flex flex-col col-span-1 gap-8"
            data-aos="fade-down"
            data-duration="200"
          >
            <div className="w-full">
              <SlideCard />
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
      <BookingModal
        visible={bookingModalVisible}
        setVisible={setBookingModalVisible}
      />
    </>
  );
};

export default Home;
