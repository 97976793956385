import {
    FCM_SETTINGS,
    SET_BRANCHES,
    SIGN_IN_TO_CONFIRM_BOOKING,
    VEHICLE_BY_TYPE,
    VEHICLE_BY_TYPE_PAGINATION,
    VEHICLE_TYPE
} from '../type';

const initialState = {
    branches: [],
    vehicleTypes: [],
    vehiclesByType: [],
    fcm_settings: [],
    sign_in_to_confirm_booking: false
}

const globalDataReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_BRANCHES:
            return {
                ...state,
                branches: payload,
            }
        case VEHICLE_TYPE:
            return {
                ...state,
                vehicleTypes: payload,
            }
        case VEHICLE_BY_TYPE:
            return {
                ...state,
                vehiclesByType: payload,
            }
        case FCM_SETTINGS:
            return {
                ...state,
                fcm_settings: payload,
            }
        case SIGN_IN_TO_CONFIRM_BOOKING:
            return {
                ...state,
                sign_in_to_confirm_booking: payload,
            }
        case VEHICLE_BY_TYPE_PAGINATION:
            return {
                ...state,
                vehiclesByTypePagination: payload,
            }
        default:
            return state;
    }
}

export default globalDataReducer;
