import BreadCrumb from "../../components/BreadCrumb";
import { BsShieldCheck } from "react-icons/bs";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { GrCar } from "react-icons/gr";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <>
      <div className="w-full">
        <BreadCrumb title="About Us">
          <Link to="/">Home</Link> - <Link to="#">About Us</Link>
        </BreadCrumb>
        <img src="image/aboutus/banner.png" className="w-full" alt="" />
      </div>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] md:px-[10%] m-auto py-12 font-semibold leading-8 text-center flex flex-col items-center gap-4">
          <h1 className="text-[33px] text-[var(--text-color) pb-8 font-[roboto]">
            <i>Your Reliable Choice for Safety and Comfort on the Road</i>
          </h1>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            YOKS Rent-A-Car Limited, a subsidiary of YOKS Investment Limited,
            provides car rentals services and the best value for money
            transportation solutions for businesses in Ghana. We offer services
            to both individual clients and corporate/business clients.
          </p>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            Over the years, we have shown our superiority and market dominance
            managing a fleet of over 100 vehicles to serve the local market. We
            have an efficient management team and professionally trained drivers
            combined with an impressive safety record over the past 22 years.
            Safety, reliability, reputation, and integrity are some of the
            hallmark qualities of YOKS.
          </p>
          <div>
            <p className="text-left">
              We have built a remarkable list of clients over the last two
              decades, including:
            </p>
            <ul className="list-disc ml-6 text-left">
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                WHO (World health Organization)
              </li>
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                Several UN Agencies, and NGOs.
              </li>
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                Ghana Government Ministries & State Protocol
              </li>
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                Multinational Companies
              </li>
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                Local Business and Companies
              </li>
              <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
                Hoteliers (including Movenpick Hotel and most luxury brand
                hotels in Accra)
              </li>
            </ul>
          </div>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            We have a demonstrable competitive advantage over others in the
            industry; and we continue to improve in the area of customer
            experience (CX). YOKS is highly committed to technology—with
            substantial investment in tech-focused initiatives, such as:
          </p>
          <ul className="list-disc ml-6 text-left">
            <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
              An enhanced website with CRM functionality that will enable
              clients and customers to directly make bookings and reservations
              and payments from the website or mobile app.
            </li>
            <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
              Company intranet integrated with a proprietary fleet management
              system (FMS) to track all activities; as well as to enable 3rd
              Party vehicle owners to easily track their vehicle, including
              revenue being generated in real time.
            </li>
          </ul>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            YOKS has won various awards over the past years relating to its
            high-quality service, including:
          </p>
          <ul className="list-disc ml-6 text-left">
            <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
              National Tourism Awards, for Best Car Rental Company in 2012 &
              2013
            </li>
            <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
              Chartered Institute of Marketing Ghana (CIMG) Awards for
              Hospitality Facility of Year in 2012, 2013 & 2014
            </li>
            <li className="text-lg text-[var(--text-color)] leading-8 font-text ">
              Ghana Business & Financial Services Excellence Gold Awards in
              2013.
            </li>
          </ul>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            The company’s founder and CEO, Mr. Seth Yeboah Ocran, started YOKS
            22 years ago, with only one car, and a single customer, with an
            annual turnover of $20,000 in its first year of business.
          </p>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            Through hard work, resilience and perseverance, Mr. Ocran has been
            able to strategically transform YOKS from its humble beginnings as a
            sole proprietorship one-car-business into a notable main player in
            the industry that offers complete transport management services in
            its market.
          </p>
          <p className="text-lg text-[var(--text-color)] leading-8 font-text">
            Today, YOKS has since grown 10,000%, a main player in car rentals
            with a proven track record and acknowledged as “the reliable leader”
            in the car rental, finance lease and fleet management industry, in
            Ghana.
          </p>
        </div>
      </div>
      {/* <div
        className="w-full h-max lg:h-[275px] overflow-hidden relative"
        style={{
          backgroundImage: "url('image/home/number_back.svg')",
        }}
      >
        <div className="max-w-[1280px] w-[90%] h-full grid grid-cols-1 md:grid-cols-2 items-center justify-center m-auto py-2">
          <div
            className=" text-white flex flex-wrap gap-2 md:block text-[28px] md:text-[35px] lg:text-[40px] font-semibold col-span-1"
            data-aos="fade-right"
          >
            <p>Showcase some</p>
            <p>impressive numbers.</p>
          </div>
          <div
            className="flex items-center justify-between col-span-1"
            data-aos="fade-left"
          >
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">22</p>
              <p>Years</p>
            </div>
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">
                Over 200,000
              </p>
              <p>Miles</p>
            </div>
            <div className="text-center text-white">
              <p className="text-[40px] font-semibold leading-tight">13K</p>
              <p>Happy Customers</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="w-full py-6">
        <div className="max-w-[1280px] w-[90%] m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-[var(--text-color)] font-semibold gap-8">
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <GrCar className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">MISSION</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              To provide our clients with excellent service at all times with a
              competent and professional staff, and to create value for our
              stakeholders; by ensuring complete customer satisfaction through
              excellence, efficiency and security.
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <BsShieldCheck className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">ACCOUNTABILITY</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              We demand accountability in every aspect of our business from all
              our staff.
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <RiMoneyDollarCircleLine className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">SAFETY</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              We make an extra effort to ensure that safety standard are at
              their highest peak and adhered to at all times.
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <GrCar className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">INTEGRITY</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              We are respectful and responsible for following through on our
              commitments to clients and other stakeholders.
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <BsShieldCheck className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">VISION</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              To be the reliable leader in the car rentals and travel & tourism
              sector
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <RiMoneyDollarCircleLine className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">
              PEOPLE DEVELOPMENT
            </h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              To develop the human talent of our staff ,community and associates
            </p>
          </div>
          <div className="flex flex-col items-center col-span-1 gap-4 sm:px-[10%] text-center">
            <div className="rounded-full bg-[#FDEEEC] w-12 h-12 flex items-center justify-center">
              <GrCar className="text-[var(--text-color)] font-bold text-2xl" />
            </div>
            <h3 className="text-lg text-[var(--text-color)]">Achievement</h3>
            <p className="text-sm text-[var(--text-color)] text-center leading-6">
              We believe in achieving challenging goals through superior
              performance and rewards.
            </p>
          </div>
        </div>
      </div>
      <div
        className="w-full py-60"
        style={{
          backgroundImage: "url(image/aboutus/csr_background.png)",
          backgroundSize: "cover",
        }}
      >
        <div className="max-w-[1280px] w-[90%] m-auto text-center text-white">
          <h1 className="font-semibold text-7xl">CSR</h1>
          <p className="text-3xl leading-10 mt-36">
            YOKS is committed to planting 20 trees every quarter by contracting
            the Youth to provide gainful employment as it implements it plans in
            Accra. YOKS to adopt 5 major streets the next 5 years to plant trees
            along. By doing this, YOKS is aligning itself with the UN SDG # 13
            of Urgent climate action to combat climate change.
          </p>
        </div>
      </div>
      <div className="w-full">
        <img
          src="image/home/footer_illustration.svg"
          alt=""
          className="w-full"
        />
      </div>
    </>
  );
};

export default Aboutus;
