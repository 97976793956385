import { Link, useNavigate } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

const ServiceCard = (props) => {
  const { img, title, url } = props;
  const Navigate = useNavigate();

  return (
    <div
      className="flex flex-col justify-between w-full h-full gap-3 cursor-pointer"
      onClick={() => Navigate(`/service_detail?name=${url}`)}
    >
      <div className="flex flex-col gap-4">
        <img
          src={`image/services/${img}`}
          className="w-full rounded-md"
          alt=""
        />
        <h1 className="text-2xl">{title}</h1>
        {/* <p className="text-base">{text}</p> */}
      </div>
      <div className="text-[var(--main-color)] flex gap-4 items-center">
        {/* <Link
          to={`/service_detail?name=${url}`}
        
        > */}
        <span className="text-base">Learn more</span>
        <BsArrowUpRight />
        {/* </Link> */}
      </div>
    </div>
  );
};

export default ServiceCard;
