import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import GooglePlayButton from "./../../components/GooglePlayButton";
import AppleButton from "./../../components/AppleButton";
export default function DownloadApp() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="bg-[#FDEEEC] mt-48 bg-right bg-no-repeat bg-[length:auto_100%] h-[430px] relative flex items-center justify-center" style={{ backgroundImage: 'url("image/home/app1.png")' }}>
      <img
        alt="Person using smartphone"
        className="absolute left-0 bottom-0 h-[130%] hidden md:block"
        src="image/home/wepik-export-20240225042018UCO9 1.png"
      />
      <div className="absolute w-full h-full bg-white/60 block md:hidden z-10"></div>
      <div className="w-[90%] max-w-sm z-20" data-aos="fade-up">
        <h2 className="text-4xl font-bold mb-4">Download the app</h2>
        <p className="mb-8">
          Have a personal driver at your fingertips no matter where you are
          with our easy-to-use smartphone app. (Coming Soon)
        </p>
        <div className="flex gap-4">
          <AppleButton />
          <GooglePlayButton />
        </div>
      </div>
    </section>
  );
}

function AppleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 20.94c1.5 0 2.75 1.06 4 1.06 3 0 6-8 6-12.22A4.91 4.91 0 0 0 17 5c-2.22 0-4 1.44-5 2-1-.56-2.78-2-5-2a4.9 4.9 0 0 0-5 4.78C2 14 5 22 8 22c1.25 0 2.5-1.06 4-1.06Z" />
      <path d="M10 2c1 .5 2 2 2 5" />
    </svg>
  );
}

function PlayIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="5 3 19 12 5 21 5 3" />
    </svg>
  );
}
