import BreadCrumb from "../../components/BreadCrumb";
import { Link } from "react-router-dom";

const ServiceSecurityescort = () => {
  return (
    <div>
      <div className="w-full">
        <BreadCrumb title="Security Escort">
          <Link to="/">Home</Link> - <Link to="#">Services</Link>
        </BreadCrumb>
      </div>
      <div className="relative w-full">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="max-w-[1280px] w-[90%] h-full relative m-auto">
            <div className="absolute right-0 top-4 w-max h-max">
              {/* <RButton isradius={true}>
                <span className="flex items-center gap-4 px-10">
                  <FaPlay />
                  Book Now
                </span>
              </RButton> */}
            </div>
          </div>
        </div>
        <img
          src="image/services/securityescort_banner.svg"
          className="w-full"
          alt=""
        />
      </div>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] m-auto py-16 grid grid-cols-1 md:grid-cols-2 gap-16">
          <div className="col-span-1">
            <img
              src="image/services/securityescort_thumbnail1.svg"
              alt=""
              className="w-full"
            />
          </div>
          <div className="col-span-1">
            <h1 className="text-[44px] text-[var(--text-color)]">
              Security Escort Services
            </h1>
            <p className="text-[var(--text-color)] mt-4 font-text">
              We provide extra security services for clients based on formal
              requests from our clients for their travel and personnel
              protection.
            </p>
            <p className="text-[var(--text-color)] mt-4 font-text">
              A standard escort team shall comprise of:
            </p>
            <ul className="list-disc ml-6">
              <li className="text-[var(--text-color)] mt-4 font-text">
                1 Security Supervisor
              </li>
              <li className="text-[var(--text-color)] mt-4 font-text">
                1 Security Driver
              </li>
              <li className="text-[var(--text-color)] mt-4 font-text">
                1 or 2 armed police officers.{" "}
              </li>
            </ul>
            <p className="text-[var(--text-color)] mt-4 font-text">
              We are also able to arrange with the Ghana police MTTD to provide
              a single motorcade escort as an alternative arrangement. Escorts
              shall be carried out with one passenger vehicle as requested by
              the client, and an additional escort vehicle with a 4x4 vehicle,
              when necessary.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSecurityescort;
