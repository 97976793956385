import React, { useState } from "react";

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showSearchInput, setShowSearchInput] = useState(false);

  return (
    <div className=" inset-0 flex  justify-center items-start pt-12">
      <div className=" w-full  mx-auto rounded-lg overflow-hidden shadow-lg">
        <div className="flex max-w-md ">
          <button
            className={`flex-1 py-3 text-center text-sm font-semibold focus:outline-none bg-white rounded-lg ${
              activeTab === 1 ? "bg-orange-500 text-white" : "text-gray-600"
            }`}
            onClick={() => setActiveTab(1)}
          >
            <span>Whole Day</span>
          </button>
          <button
            className={`flex-1 py-3 text-center text-sm font-semibold focus:outline-none bg-white rounded-lg ${
              activeTab === 2 ? "bg-orange-500 text-white" : "text-gray-600"
            }`}
            onClick={() => setActiveTab(2)}
          >
            <span>Hourly</span>
          </button>
        </div>
        <div className="p-4 bg-white rounded-xl ">
          {activeTab === 1 && (
            <div className="grid grid-cols-1 gap-2 md:grid-cols-5 md:max-h-24">
              <div className="flex gap-2  items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="6" fill="white" />
                  <path
                    d="M28.5206 14.1778H27.2128V12.2222H25.9111V14.1778H18.0889V12.2222H16.7872V14.1778H15.4795C14.6161 14.1794 13.7885 14.5231 13.178 15.1336C12.5675 15.7441 12.2238 16.5716 12.2222 17.435V28.5206C12.2238 29.3839 12.5675 30.2115 13.178 30.822C13.7885 31.4325 14.6161 31.7762 15.4795 31.7778H28.5206C29.3839 31.7762 30.2115 31.4325 30.822 30.822C31.4325 30.2115 31.7762 29.3839 31.7778 28.5206V17.435C31.7762 16.5716 31.4325 15.7441 30.822 15.1336C30.2115 14.5231 29.3839 14.1794 28.5206 14.1778ZM30.4761 28.5206C30.4761 29.0392 30.2701 29.5366 29.9034 29.9034C29.5366 30.2701 29.0392 30.4761 28.5206 30.4761H15.4795C14.9608 30.4761 14.4634 30.2701 14.0967 29.9034C13.7299 29.5366 13.5239 29.0392 13.5239 28.5206V17.435C13.5239 16.9164 13.7299 16.419 14.0967 16.0522C14.4634 15.6855 14.9608 15.4795 15.4795 15.4795H16.7872V17.435H18.0889V15.4795H25.9111V17.435H27.2128V15.4795H28.5145C29.0331 15.4795 29.5305 15.6855 29.8972 16.0522C30.264 16.419 30.47 16.9164 30.47 17.435L30.4761 28.5206Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M27.2128 23.3016H24.6094C24.2642 23.3016 23.9331 23.4388 23.689 23.6829C23.4449 23.927 23.3078 24.2581 23.3078 24.6033V27.2127C23.3078 27.558 23.4449 27.8891 23.689 28.1332C23.9331 28.3773 24.2642 28.5144 24.6094 28.5144H27.2189C27.5641 28.5144 27.8952 28.3773 28.1393 28.1332C28.3834 27.8891 28.5205 27.558 28.5205 27.2127V24.6094C28.5205 24.2642 28.3834 23.9331 28.1393 23.689C27.8952 23.4449 27.5641 23.3077 27.2189 23.3077L27.2128 23.3016ZM27.2128 27.2127H24.6094V24.6094H27.2189L27.2128 27.2127Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">Date </span>
                  <br />
                  <span className="text-gray-700">Thu, Oct 06, 2022</span>
                </div>
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="6" fill="white" />
                  <path
                    d="M22 12.2222C20.0662 12.2222 18.1757 12.7957 16.5678 13.8701C14.9598 14.9445 13.7066 16.4716 12.9665 18.2582C12.2265 20.0449 12.0328 22.0109 12.4101 23.9076C12.7874 25.8043 13.7186 27.5465 15.0861 28.9139C16.4535 30.2814 18.1958 31.2126 20.0925 31.5899C21.9892 31.9672 23.9552 31.7736 25.7418 31.0335C27.5285 30.2934 29.0555 29.0402 30.1299 27.4323C31.2043 25.8243 31.7778 23.9339 31.7778 22C31.7778 19.4068 30.7476 16.9198 28.9139 15.0861C27.0803 13.2524 24.5932 12.2222 22 12.2222ZM13.5239 22C13.5211 19.8084 14.3679 17.701 15.8863 16.1206C17.4048 14.5403 19.4767 13.61 21.6667 13.5254C23.8567 13.4407 25.9943 14.2082 27.6302 15.6667C29.2661 17.1251 30.2731 19.1608 30.4395 21.3461H28.5206C28.3471 21.3461 28.1808 21.415 28.0582 21.5376C27.9356 21.6603 27.8667 21.8266 27.8667 22C27.8651 23.5554 27.2464 25.0467 26.1466 26.1466C25.0467 27.2464 23.5555 27.8651 22 27.8667C21.8266 27.8667 21.6603 27.9356 21.5376 28.0582C21.415 28.1808 21.3461 28.3471 21.3461 28.5206V30.4456C19.2191 30.2796 17.2328 29.3181 15.783 27.7528C14.3333 26.1876 13.5267 24.1335 13.5239 22ZM22.6539 30.4395V29.1439C24.3231 28.9898 25.8858 28.2565 27.0712 27.0712C28.2565 25.8858 28.9898 24.3231 29.1439 22.6539H30.4456C30.2871 24.667 29.4152 26.5575 27.9867 27.9848C26.5583 29.4122 24.6672 30.2826 22.6539 30.4395Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M23.4972 24.4444L24.4445 23.4972L22.6784 21.7311V17.4533H21.3461V22C21.3466 22.1717 21.4147 22.3363 21.5356 22.4583L23.4972 24.4444Z"
                    fill="#181A1F"
                  />
                </svg>

                <div>
                  <span className="text-gray-400">Time </span>
                  <br />
                  <span className="text-gray-700">6 PM : 15</span>
                </div>
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 20.7778C12.9338 20.7778 14.8243 20.2043 16.4322 19.1299C18.0402 18.0555 19.2934 16.5285 20.0335 14.7418C20.7735 12.9552 20.9672 10.9892 20.5899 9.09246C20.2126 7.19576 19.2814 5.45353 17.9139 4.08608C16.5465 2.71863 14.8042 1.78739 12.9075 1.41011C11.0108 1.03283 9.04485 1.22647 7.25819 1.96652C5.47154 2.70658 3.94446 3.95982 2.87006 5.56777C1.79567 7.17572 1.22221 9.06615 1.22221 11C1.22221 13.5932 2.25237 16.0803 4.08605 17.9139C5.91974 19.7476 8.40676 20.7778 11 20.7778ZM11 2.5239C12.6764 2.5239 14.3152 3.02102 15.7091 3.95238C17.103 4.88375 18.1894 6.20754 18.8309 7.75634C19.4724 9.30515 19.6403 11.0094 19.3132 12.6536C18.9862 14.2978 18.1789 15.8081 16.9935 16.9935C15.8081 18.1789 14.2978 18.9862 12.6536 19.3133C11.0094 19.6403 9.30513 19.4725 7.75632 18.8309C6.20751 18.1894 4.88373 17.103 3.95236 15.7091C3.02099 14.3152 2.52388 12.6764 2.52388 11C2.52549 8.7525 3.41903 6.59751 5.00826 5.00828C6.59749 3.41905 8.75248 2.52552 11 2.5239Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M8.39058 14.5139H12.9556V13.2061H8.39058C8.03968 13.2061 7.70316 13.0667 7.45504 12.8186C7.20692 12.5705 7.06752 12.2339 7.06752 11.883C7.06752 11.5321 7.20692 11.1956 7.45504 10.9475C7.70316 10.6994 8.03968 10.56 8.39058 10.56H15.565C15.6989 10.5591 15.8292 10.5171 15.9384 10.4398C16.0476 10.3624 16.1305 10.2534 16.1758 10.1275C16.2211 10.0015 16.2267 9.86475 16.1918 9.73554C16.1569 9.60634 16.0831 9.49096 15.9806 9.40499L13.3711 7.22943L12.5339 8.23165L13.7561 9.25221H8.38447C7.6924 9.25221 7.02868 9.52713 6.53931 10.0165C6.04995 10.5059 5.77503 11.1696 5.77503 11.8617C5.77176 12.5597 6.04512 13.2307 6.53529 13.7277C7.02545 14.2248 7.69253 14.5074 8.39058 14.5139Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">From </span>
                  <br />
                  <span className="text-gray-700">
                    London City Airport (LCY)
                  </span>
                </div>{" "}
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 20.7778C9.06615 20.7778 7.17572 20.2043 5.56777 19.1299C3.95982 18.0555 2.70658 16.5285 1.96652 14.7418C1.22647 12.9552 1.03283 10.9892 1.41011 9.09246C1.78739 7.19576 2.71863 5.45353 4.08608 4.08608C5.45353 2.71863 7.19576 1.78739 9.09246 1.41011C10.9892 1.03283 12.9552 1.22647 14.7418 1.96652C16.5285 2.70658 18.0555 3.95982 19.1299 5.56777C20.2043 7.17572 20.7778 9.06615 20.7778 11C20.7778 13.5932 19.7476 16.0803 17.9139 17.9139C16.0803 19.7476 13.5932 20.7778 11 20.7778ZM11 2.5239C9.3236 2.5239 7.68483 3.02102 6.29094 3.95238C4.89705 4.88375 3.81064 6.20754 3.16911 7.75634C2.52757 9.30515 2.35971 11.0094 2.68677 12.6536C3.01382 14.2978 3.82109 15.8081 5.0065 16.9935C6.1919 18.1789 7.7022 18.9862 9.34641 19.3133C10.9906 19.6403 12.6949 19.4725 14.2437 18.8309C15.7925 18.1894 17.1163 17.103 18.0476 15.7091C18.979 14.3152 19.4761 12.6764 19.4761 11C19.4745 8.7525 18.581 6.59751 16.9917 5.00828C15.4025 3.41905 13.2475 2.52552 11 2.5239Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M13.6094 14.5139H9.04442V13.2061H13.6094C13.9603 13.2061 14.2968 13.0667 14.545 12.8186C14.7931 12.5705 14.9325 12.2339 14.9325 11.883C14.9325 11.5321 14.7931 11.1956 14.545 10.9475C14.2968 10.6994 13.9603 10.56 13.6094 10.56H6.43498C6.30114 10.5591 6.17082 10.5171 6.06159 10.4398C5.95237 10.3624 5.8695 10.2534 5.82419 10.1275C5.77889 10.0015 5.77331 9.86475 5.80822 9.73554C5.84313 9.60634 5.91685 9.49096 6.01942 9.40499L8.62887 7.22943L9.46609 8.23165L8.24387 9.25221H13.6155C14.3076 9.25221 14.9713 9.52713 15.4607 10.0165C15.9501 10.5059 16.225 11.1696 16.225 11.8617C16.2282 12.5597 15.9549 13.2307 15.4647 13.7277C14.9745 14.2248 14.3075 14.5074 13.6094 14.5139Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">To </span>
                  <br />
                  <span className="text-gray-700">
                    London City Airport (LCY)
                  </span>
                </div>{" "}
              </div>
              {showSearchInput ? (
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full h-[90px] m-auto px-3 py-4 mb-4 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500"
                />
              ) : (
                <button
                  className="bg-orange-500 h-[90px]  my-auto flex justify-center items-center text-white tex-xl px-3 py-4 mb-4  rounded-md "
                  onClick={() => setShowSearchInput(true)}
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_569)">
                      <path
                        d="M20.3062 18.8625L15.7437 14.3C17.2066 12.5932 17.9514 10.3861 17.8219 8.14189C17.6925 5.89771 16.6988 3.79091 15.0494 2.26362C13.4 0.736328 11.2231 -0.092719 8.97562 -0.0495418C6.72812 -0.00636454 4.58471 0.905681 2.99519 2.49519C1.40568 4.08471 0.493635 6.22812 0.450458 8.47562C0.407281 10.7231 1.23633 12.9 2.76362 14.5494C4.29091 16.1988 6.39771 17.1925 8.64189 17.3219C10.8861 17.4514 13.0932 16.7066 14.8 15.2437L19.3625 19.8062C19.4901 19.9183 19.6556 19.9775 19.8254 19.972C19.9951 19.9665 20.1564 19.8966 20.2765 19.7765C20.3966 19.6564 20.4665 19.4951 20.472 19.3254C20.4775 19.1556 20.4183 18.9901 20.3062 18.8625ZM1.83125 8.66875C1.83125 7.21903 2.26107 5.80186 3.06636 4.59638C3.87165 3.3909 5.01626 2.45123 6.35551 1.89616C7.69476 1.34109 9.16851 1.19554 10.5905 1.47792C12.0124 1.76029 13.3187 2.4579 14.3443 3.48257C15.3698 4.50724 16.0685 5.81296 16.3521 7.23467C16.6357 8.65638 16.4914 10.1303 15.9375 11.47C15.3836 12.8097 14.4449 13.9551 13.2401 14.7614C12.0353 15.5677 10.6185 15.9988 9.16875 16C7.22431 15.9983 5.3599 15.2256 3.98439 13.8513C2.60888 12.4769 1.83456 10.6132 1.83125 8.66875Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_569">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Search
                </button>
              )}
            </div>
          )}
          {activeTab === 2 && (
            <div className="grid grid-cols-1 gap-2 md:grid-cols-5 md:max-h-24">
              <div className="flex gap-2  items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="6" fill="white" />
                  <path
                    d="M28.5206 14.1778H27.2128V12.2222H25.9111V14.1778H18.0889V12.2222H16.7872V14.1778H15.4795C14.6161 14.1794 13.7885 14.5231 13.178 15.1336C12.5675 15.7441 12.2238 16.5716 12.2222 17.435V28.5206C12.2238 29.3839 12.5675 30.2115 13.178 30.822C13.7885 31.4325 14.6161 31.7762 15.4795 31.7778H28.5206C29.3839 31.7762 30.2115 31.4325 30.822 30.822C31.4325 30.2115 31.7762 29.3839 31.7778 28.5206V17.435C31.7762 16.5716 31.4325 15.7441 30.822 15.1336C30.2115 14.5231 29.3839 14.1794 28.5206 14.1778ZM30.4761 28.5206C30.4761 29.0392 30.2701 29.5366 29.9034 29.9034C29.5366 30.2701 29.0392 30.4761 28.5206 30.4761H15.4795C14.9608 30.4761 14.4634 30.2701 14.0967 29.9034C13.7299 29.5366 13.5239 29.0392 13.5239 28.5206V17.435C13.5239 16.9164 13.7299 16.419 14.0967 16.0522C14.4634 15.6855 14.9608 15.4795 15.4795 15.4795H16.7872V17.435H18.0889V15.4795H25.9111V17.435H27.2128V15.4795H28.5145C29.0331 15.4795 29.5305 15.6855 29.8972 16.0522C30.264 16.419 30.47 16.9164 30.47 17.435L30.4761 28.5206Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M27.2128 23.3016H24.6094C24.2642 23.3016 23.9331 23.4388 23.689 23.6829C23.4449 23.927 23.3078 24.2581 23.3078 24.6033V27.2127C23.3078 27.558 23.4449 27.8891 23.689 28.1332C23.9331 28.3773 24.2642 28.5144 24.6094 28.5144H27.2189C27.5641 28.5144 27.8952 28.3773 28.1393 28.1332C28.3834 27.8891 28.5205 27.558 28.5205 27.2127V24.6094C28.5205 24.2642 28.3834 23.9331 28.1393 23.689C27.8952 23.4449 27.5641 23.3077 27.2189 23.3077L27.2128 23.3016ZM27.2128 27.2127H24.6094V24.6094H27.2189L27.2128 27.2127Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">Date </span>
                  <br />
                  <span className="text-gray-700">Thu, Oct 06, 2022</span>
                </div>
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="6" fill="white" />
                  <path
                    d="M22 12.2222C20.0662 12.2222 18.1757 12.7957 16.5678 13.8701C14.9598 14.9445 13.7066 16.4716 12.9665 18.2582C12.2265 20.0449 12.0328 22.0109 12.4101 23.9076C12.7874 25.8043 13.7186 27.5465 15.0861 28.9139C16.4535 30.2814 18.1958 31.2126 20.0925 31.5899C21.9892 31.9672 23.9552 31.7736 25.7418 31.0335C27.5285 30.2934 29.0555 29.0402 30.1299 27.4323C31.2043 25.8243 31.7778 23.9339 31.7778 22C31.7778 19.4068 30.7476 16.9198 28.9139 15.0861C27.0803 13.2524 24.5932 12.2222 22 12.2222ZM13.5239 22C13.5211 19.8084 14.3679 17.701 15.8863 16.1206C17.4048 14.5403 19.4767 13.61 21.6667 13.5254C23.8567 13.4407 25.9943 14.2082 27.6302 15.6667C29.2661 17.1251 30.2731 19.1608 30.4395 21.3461H28.5206C28.3471 21.3461 28.1808 21.415 28.0582 21.5376C27.9356 21.6603 27.8667 21.8266 27.8667 22C27.8651 23.5554 27.2464 25.0467 26.1466 26.1466C25.0467 27.2464 23.5555 27.8651 22 27.8667C21.8266 27.8667 21.6603 27.9356 21.5376 28.0582C21.415 28.1808 21.3461 28.3471 21.3461 28.5206V30.4456C19.2191 30.2796 17.2328 29.3181 15.783 27.7528C14.3333 26.1876 13.5267 24.1335 13.5239 22ZM22.6539 30.4395V29.1439C24.3231 28.9898 25.8858 28.2565 27.0712 27.0712C28.2565 25.8858 28.9898 24.3231 29.1439 22.6539H30.4456C30.2871 24.667 29.4152 26.5575 27.9867 27.9848C26.5583 29.4122 24.6672 30.2826 22.6539 30.4395Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M23.4972 24.4444L24.4445 23.4972L22.6784 21.7311V17.4533H21.3461V22C21.3466 22.1717 21.4147 22.3363 21.5356 22.4583L23.4972 24.4444Z"
                    fill="#181A1F"
                  />
                </svg>

                <div>
                  <span className="text-gray-400">Time </span>
                  <br />
                  <span className="text-gray-700">6 PM : 15</span>
                </div>
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 20.7778C12.9338 20.7778 14.8243 20.2043 16.4322 19.1299C18.0402 18.0555 19.2934 16.5285 20.0335 14.7418C20.7735 12.9552 20.9672 10.9892 20.5899 9.09246C20.2126 7.19576 19.2814 5.45353 17.9139 4.08608C16.5465 2.71863 14.8042 1.78739 12.9075 1.41011C11.0108 1.03283 9.04485 1.22647 7.25819 1.96652C5.47154 2.70658 3.94446 3.95982 2.87006 5.56777C1.79567 7.17572 1.22221 9.06615 1.22221 11C1.22221 13.5932 2.25237 16.0803 4.08605 17.9139C5.91974 19.7476 8.40676 20.7778 11 20.7778ZM11 2.5239C12.6764 2.5239 14.3152 3.02102 15.7091 3.95238C17.103 4.88375 18.1894 6.20754 18.8309 7.75634C19.4724 9.30515 19.6403 11.0094 19.3132 12.6536C18.9862 14.2978 18.1789 15.8081 16.9935 16.9935C15.8081 18.1789 14.2978 18.9862 12.6536 19.3133C11.0094 19.6403 9.30513 19.4725 7.75632 18.8309C6.20751 18.1894 4.88373 17.103 3.95236 15.7091C3.02099 14.3152 2.52388 12.6764 2.52388 11C2.52549 8.7525 3.41903 6.59751 5.00826 5.00828C6.59749 3.41905 8.75248 2.52552 11 2.5239Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M8.39058 14.5139H12.9556V13.2061H8.39058C8.03968 13.2061 7.70316 13.0667 7.45504 12.8186C7.20692 12.5705 7.06752 12.2339 7.06752 11.883C7.06752 11.5321 7.20692 11.1956 7.45504 10.9475C7.70316 10.6994 8.03968 10.56 8.39058 10.56H15.565C15.6989 10.5591 15.8292 10.5171 15.9384 10.4398C16.0476 10.3624 16.1305 10.2534 16.1758 10.1275C16.2211 10.0015 16.2267 9.86475 16.1918 9.73554C16.1569 9.60634 16.0831 9.49096 15.9806 9.40499L13.3711 7.22943L12.5339 8.23165L13.7561 9.25221H8.38447C7.6924 9.25221 7.02868 9.52713 6.53931 10.0165C6.04995 10.5059 5.77503 11.1696 5.77503 11.8617C5.77176 12.5597 6.04512 13.2307 6.53529 13.7277C7.02545 14.2248 7.69253 14.5074 8.39058 14.5139Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">From </span>
                  <br />
                  <span className="text-gray-700">
                    London City Airport (LCY)
                  </span>
                </div>{" "}
              </div>
              <div className="flex gap-2 items-center border border-gray-200 border-1 rounded-lg  px-3 py-4 mb-4">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 20.7778C9.06615 20.7778 7.17572 20.2043 5.56777 19.1299C3.95982 18.0555 2.70658 16.5285 1.96652 14.7418C1.22647 12.9552 1.03283 10.9892 1.41011 9.09246C1.78739 7.19576 2.71863 5.45353 4.08608 4.08608C5.45353 2.71863 7.19576 1.78739 9.09246 1.41011C10.9892 1.03283 12.9552 1.22647 14.7418 1.96652C16.5285 2.70658 18.0555 3.95982 19.1299 5.56777C20.2043 7.17572 20.7778 9.06615 20.7778 11C20.7778 13.5932 19.7476 16.0803 17.9139 17.9139C16.0803 19.7476 13.5932 20.7778 11 20.7778ZM11 2.5239C9.3236 2.5239 7.68483 3.02102 6.29094 3.95238C4.89705 4.88375 3.81064 6.20754 3.16911 7.75634C2.52757 9.30515 2.35971 11.0094 2.68677 12.6536C3.01382 14.2978 3.82109 15.8081 5.0065 16.9935C6.1919 18.1789 7.7022 18.9862 9.34641 19.3133C10.9906 19.6403 12.6949 19.4725 14.2437 18.8309C15.7925 18.1894 17.1163 17.103 18.0476 15.7091C18.979 14.3152 19.4761 12.6764 19.4761 11C19.4745 8.7525 18.581 6.59751 16.9917 5.00828C15.4025 3.41905 13.2475 2.52552 11 2.5239Z"
                    fill="#181A1F"
                  />
                  <path
                    d="M13.6094 14.5139H9.04442V13.2061H13.6094C13.9603 13.2061 14.2968 13.0667 14.545 12.8186C14.7931 12.5705 14.9325 12.2339 14.9325 11.883C14.9325 11.5321 14.7931 11.1956 14.545 10.9475C14.2968 10.6994 13.9603 10.56 13.6094 10.56H6.43498C6.30114 10.5591 6.17082 10.5171 6.06159 10.4398C5.95237 10.3624 5.8695 10.2534 5.82419 10.1275C5.77889 10.0015 5.77331 9.86475 5.80822 9.73554C5.84313 9.60634 5.91685 9.49096 6.01942 9.40499L8.62887 7.22943L9.46609 8.23165L8.24387 9.25221H13.6155C14.3076 9.25221 14.9713 9.52713 15.4607 10.0165C15.9501 10.5059 16.225 11.1696 16.225 11.8617C16.2282 12.5597 15.9549 13.2307 15.4647 13.7277C14.9745 14.2248 14.3075 14.5074 13.6094 14.5139Z"
                    fill="#181A1F"
                  />
                </svg>
                <div>
                  <span className="text-gray-400">To </span>
                  <br />
                  <span className="text-gray-700">
                    London City Airport (LCY)
                  </span>
                </div>{" "}
              </div>
              {showSearchInput ? (
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full h-[90px] m-auto px-3 py-4 mb-4 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500"
                />
              ) : (
                <button
                  className="bg-orange-500 h-[90px]  my-auto flex justify-center items-center text-white tex-xl px-3 py-4 mb-4  rounded-md "
                  onClick={() => setShowSearchInput(true)}
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_569)">
                      <path
                        d="M20.3062 18.8625L15.7437 14.3C17.2066 12.5932 17.9514 10.3861 17.8219 8.14189C17.6925 5.89771 16.6988 3.79091 15.0494 2.26362C13.4 0.736328 11.2231 -0.092719 8.97562 -0.0495418C6.72812 -0.00636454 4.58471 0.905681 2.99519 2.49519C1.40568 4.08471 0.493635 6.22812 0.450458 8.47562C0.407281 10.7231 1.23633 12.9 2.76362 14.5494C4.29091 16.1988 6.39771 17.1925 8.64189 17.3219C10.8861 17.4514 13.0932 16.7066 14.8 15.2437L19.3625 19.8062C19.4901 19.9183 19.6556 19.9775 19.8254 19.972C19.9951 19.9665 20.1564 19.8966 20.2765 19.7765C20.3966 19.6564 20.4665 19.4951 20.472 19.3254C20.4775 19.1556 20.4183 18.9901 20.3062 18.8625ZM1.83125 8.66875C1.83125 7.21903 2.26107 5.80186 3.06636 4.59638C3.87165 3.3909 5.01626 2.45123 6.35551 1.89616C7.69476 1.34109 9.16851 1.19554 10.5905 1.47792C12.0124 1.76029 13.3187 2.4579 14.3443 3.48257C15.3698 4.50724 16.0685 5.81296 16.3521 7.23467C16.6357 8.65638 16.4914 10.1303 15.9375 11.47C15.3836 12.8097 14.4449 13.9551 13.2401 14.7614C12.0353 15.5677 10.6185 15.9988 9.16875 16C7.22431 15.9983 5.3599 15.2256 3.98439 13.8513C2.60888 12.4769 1.83456 10.6132 1.83125 8.66875Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_569">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Search
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
