/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Third Party libraries
import { Modal, Tabs } from "antd";
import styled from "styled-components";

// Icon Imports
import { FaPlay } from "react-icons/fa";

const { TabPane } = Tabs;
const BModal = styled(Modal)`
  & > .ant-modal-content {
    padding: 0;
    button {
      color: white;
      top: 0px;
      right: 0px;
    }
    .ant-modal-header {
      background-color: #024273;
      padding: 15px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-block: 20px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;

const BTabs = styled(Tabs)`
  div.ant-tabs-nav,
  div.ant-tabs-content-holder {
    padding-inline: 20px;
  }
  & div.ant-tabs-tab {
    &.ant-tabs-tab-active > .ant-tabs-tab-btn {
      color: var(--text-color);
    }
    .ant-tabs-tab-btn {
      color: #faeaea;
    }
    .ant-tabs-tab-btn:focus {
      color: var(--text-color);
    }
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #024273;
  }
`;

const DevelopmentModal = ({ visible, setVisible }) => {
    const handleCancel = () => {
        console.log('dddddd');
        setVisible(false);
      };
  return (
    <BModal
      className="mt-[100px]"
      title={
        <div className="flex items-center w-full">
          <p className="font-bold text-xl text-white">Notice</p>
        </div>
      }
      width={"50%"}
      open={visible}
      onCancel={handleCancel}
    >
      {/*<Autocomplete*/}
      {/*    onPlaceSelected={(place) => console.log(place)}*/}
      {/*    className="w-full h-12 rounded-sm bg-[#F6F6F6] p-2 flex items-center justify-start gap-4"*/}
      {/*/>*/}
      <div className="mt-[-20px] shadow-lg p-5 absolute bg-white rounded-2xl z-50 w-[100%] sm:w-[110%] sm:ml-[-5%] flex justify-evenly items-center flex-col md:flex-row">
        <p className="text-[32px]">This website is under development.</p>
        <div className="flex items-center flex-col sm:flex-row">
         
         
        </div>
      </div>
      {/*className={`sm:mt-0 mt-${headerHeight + 10 + 'px'}`}*/}
     
     
    </BModal>
  );
};

export default DevelopmentModal;
