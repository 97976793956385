import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Card, TextField } from "@mui/material";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import {
  createBooking,
  gettingSettings,
  paymentAPI,
} from "../../redux/actions/bookingAction";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { SIGN_IN_TO_CONFIRM_BOOKING } from "../../redux/type";
import ChargePayment from "./ChargePayment";
import { getCookie } from "../../utils/constants";
import GuestBookingModal from "./GuestBookingModal";
import axios from "axios";

const Payment = () => {
  const userdata = useSelector((state) => state.auth.user);
  const book_state = useSelector((state) => state.book);
  const vehicleDetails = useSelector((state) => state.book?.vehicle_details);
  const user = useSelector((state) => state.auth.user);
  const [phone, setPhone] = useState(userdata?.phone);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.globalData?.fcm_settings);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [bookingResponse, setBookingResponse] = useState({});
  const navigate = useNavigate();
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [guestBooking, setGuestBooking] = useState(false);
  const [visible, setVisible] = useState(false);
  const [paymentEmail, setPaymentEmail] = useState("");
  const [totalpriceGhcVal, setTotalPriceGhcVal] = useState(1);
  const totalpriceGhc = async () => {
    try {
      const ghcGet = await axios.get(
        "https://latest.currency-api.pages.dev/v1/currencies/usd.json"
      );
      const ghcRate = convertToDecimal(ghcGet.data?.usd.ghs);
      console.log("ghs Rate is:", ghcRate);

      const priceInGhc = convertToDecimal(
        (calculateTax(hourlyPrice(), totalTaxValue()) +
          hourlyPrice() +
          calculateTax(totalAddonPrice(), totalTaxValue()) +
          totalAddonPrice()) *
          ghcRate
      );
      console.log("Price is:", priceInGhc);

      return priceInGhc;
    } catch (error) {
      console.error("Error fetching GHC rate:", error);
      return null;
    }
  };
  const outsideCost = () => {
    let finalCost = 0;
    if (book_state?.booking_details?.outsideDestination) {
      finalCost = book_state?.booking_details?.outsideCost;
    }
    if (typeof finalCost === "string") {
      finalCost = parseInt(finalCost, 2);
    } else if (typeof finalCost === "number") {
      // return with 2 decimal
      finalCost = parseFloat(finalCost.toFixed(2));
    }
    return finalCost;
  };
  useEffect(() => {
    console.log(book_state);
    //   checkvaribel type
    console.log(typeof outsideCost());
    console.log(typeof parseInt(outsideCost(), 2));
    console.log(outsideCost());
    console.log(outsideCost());
    totalpriceGhc();
  }, [book_state]);
  const airportCost = () => {
    let finalCost = 0;
    if (book_state?.booking_details?.airportPickup) {
      finalCost = book_state?.vehicle_details?.airport_rate;
    }
    return convertToDecimal(finalCost);
  };
  const convertToDecimal = (value) => {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    )
      return 0;
    if (typeof value === "string") {
      value = parseInt(value, 2);
    } else if (typeof value === "number") {
      // return with 2 decimal
      value = parseFloat(value.toFixed(2));
    }
    return value;
  };
  const hourlyPrice = () => {
    let finalPrice = 0;
    if (book_state?.rtype === 1) {
      if (book_state?.serviceType?.value === "chauffeur-driven") {
        finalPrice = calculateHours(book_state?.serviceHourly.value);
      } else if (book_state?.serviceType?.value === "self-drive") {
        finalPrice = vehicleDetails?.hourly_sd;
      }
    } else if (book_state?.rtype === 2) {
      finalPrice = calculateDayPrice();
    }
    finalPrice = finalPrice + outsideCost() + airportCost();
    // make this 2 after .and make it sure it is number
    finalPrice = parseFloat(finalPrice.toFixed(2));
    return finalPrice;
  };
  const dateDifferance = () => {
    let start = moment(book_state?.startdate?.format("YYYY-MM-DD"));
    let end = moment(book_state?.enddate?.format("YYYY-MM-DD"));
    end.diff(start, "days"); // =1
    return end.diff(start, "days");
  };
  const calculatingChauffeurDriverRate = () => {
    let dateDiff = dateDifferance();
    if (dateDiff < 3) {
      return vehicleDetails?.wdwa_1_2;
    } else if (dateDiff < 7) {
      return vehicleDetails?.wdwa_3_6;
    } else if (dateDiff < 16) {
      return vehicleDetails?.wdwa_7_15;
    } else if (dateDiff < 31) {
      return vehicleDetails?.wdwa_16_30;
    }
    return 0;
  };
  const calculateSelfDriveDailyRate = () => {
    let dateDiff = dateDifferance();
    if (dateDiff < 3) {
      return vehicleDetails?.wdwa_1_2_sd;
    } else if (dateDiff < 7) {
      return vehicleDetails?.wdwa_3_6_sd;
    } else if (dateDiff < 16) {
      return vehicleDetails?.wdwa_7_15_sd;
    } else if (dateDiff < 31) {
      return vehicleDetails?.wdwa_16_30_sd;
    }
    return 0;
  };
  const calculateDayPrice = () => {
    if (book_state?.serviceType?.value === "chauffeur-driven") {
      return calculatingChauffeurDriverRate();
    } else if (book_state?.serviceType?.value === "self-drive") {
      return calculateSelfDriveDailyRate();
    }
    return 0;
  };
  const calculateHours = (hours) => {
    // let me give you a brief, hours is an integer value like 8, and in the vehicledetails we have the hourly_8 value, let's find that
    // so we have to find the hourly_8 value in the vehicledetails
    let hourly = `hourly_${hours}`;
    let price = vehicleDetails[hourly];
    return price;
  };
  const calculatingDate = (date, time) => {
    let formatted_date = date.format("YYYY-MM-DD");
    let formatted_time = time.format("HH:mm");
    let date_time = formatted_date + " " + formatted_time;
    return date_time;
  };
  const calculateAirportDate = () => {
    let date_time = "";
    if (book_state?.booking_details?.airportPickup) {
      date_time = calculatingDate(
        book_state?.booking_details?.arrivalDate,
        book_state?.booking_details?.arrivalTime
      );
      return date_time;
    }
    return date_time;
  };
  const gettingPickAndDropAddress = (type) => {
    if (type === "pickup") {
      return calculatingDate(book_state?.startdate, book_state?.starttime);
    } else if (type === "dropoff") {
      return calculatingDate(book_state?.enddate, book_state?.endtime);
    }
    return "";
  };
  useEffect(() => {
    if (settings.length === 0) {
      gettingSettings(dispatch);
    }
  }, [settings]);
  useEffect(() => {
    const fetchPrice = async () => {
      const price = await totalpriceGhc();
      setTotalPriceGhcVal(price);
    };
    fetchPrice();
  }, []);
  const checkDateDifferent = () => {
    console.log(dateDifferance());
    if (book_state?.rtype === 2) {
      if (dateDifferance() < 0 || dateDifferance() > 31) {
        toast.error("Please select a valid date");
        return false;
      }
    }
    return false;
  };
  const checkIsVehicleSelected = () => {
    if (
      book_state?.vehicle_id === 0 ||
      book_state?.vehicle_id === null ||
      book_state?.vehicle_id === undefined ||
      book_state?.vehicle_id === ""
    ) {
      toast.error("Please select a vehicle");
      return false;
    }
    if (
      (book_state?.vehicle_details?.vehicle_id ||
        book_state?.vehicle_details?.id) === 0 ||
      (book_state?.vehicle_details?.vehicle_id ||
        book_state?.vehicle_details?.id) === null ||
      (book_state?.vehicle_details?.vehicle_id ||
        book_state?.vehicle_details?.id) === undefined ||
      (book_state?.vehicle_details?.vehicle_id ||
        book_state?.vehicle_details?.id) === ""
    ) {
      toast.error("Please select a vehicle");
      return false;
    }
    return true;
  };
  const checkDestinationOutside = () => {
    if (book_state?.booking_details?.outsideDestination) {
      if (
        book_state?.booking_details?.outsideDestinationName === null ||
        book_state?.booking_details?.outsideDestinationName === undefined ||
        book_state?.booking_details?.outsideDestinationName === ""
      ) {
        toast.error("Please select a destination outside");
        return false;
      }
    }
    return true;
  };
  const checkingAirPortPickup = () => {
    if (book_state?.booking_details?.airportPickup) {
      if (
        book_state?.booking_details?.flightNo === null ||
        book_state?.booking_details?.flightNo === undefined ||
        book_state?.booking_details?.flightNo === ""
      ) {
        toast.error("Please enter a flight number");
        return false;
      }
      if (
        book_state?.booking_details?.arrivalDate === null ||
        book_state?.booking_details?.arrivalDate === undefined ||
        book_state?.booking_details?.arrivalDate === ""
      ) {
        toast.error("Please select a arrival date");
        return false;
      }
      if (
        book_state?.booking_details?.arrivalTime === null ||
        book_state?.booking_details?.arrivalTime === undefined ||
        book_state?.booking_details?.arrivalTime === ""
      ) {
        toast.error("Please select a arrival time");
        return false;
      }
    }
    return true;
  };
  const checkSomeLogicalErrors = () => {
    if (
      book_state?.booking_details?.travelers === null ||
      book_state?.booking_details?.travelers === undefined ||
      book_state?.booking_details?.travelers === "" ||
      book_state?.booking_details?.travelers === 0
    ) {
      toast.error("Please enter number of travelers");
      return false;
    }
    return true;
  };
  const checkingPricing = () => {
    // check if hourly price is 0
    if (hourlyPrice() === 0) {
      toast.error("Something Wrong with price, check the booking details");
      return false;
    }
    return true;
  };
  const checkIsFileSelected = () => {
    if (book_state?.serviceType?.value === "self-drive") {
      if (
        book_state?.booking_details?.licenseFileBase64 === null ||
        book_state?.booking_details?.licenseFileBase64 === undefined ||
        book_state?.booking_details?.licenseFileBase64 === ""
      ) {
        toast.error("Please upload your license");
        return false;
      }
      if (
        book_state?.booking_details?.passportFileBase64 === null ||
        book_state?.booking_details?.passportFileBase64 === undefined ||
        book_state?.booking_details?.passportFileBase64 === ""
      ) {
        toast.error("Please upload passport or ID Card Upload");
        return false;
      }
      return true;
    }
    return true;
  };
  const checkingDetails = () => {
    console.log(book_state);
    console.log(vehicleDetails);
    // console.log(calculateHours(5));
    // console.log(calculateHours(5));
    // if (!checkDateDifferent()) return false;
    if (!checkSomeLogicalErrors()) return;
    if (!acceptPrivacy) {
      toast.error("Please accept the privacy");
      return false;
    }
    if (!checkingDocument()) return false;
    if (!checkIsVehicleSelected()) return false;
    if (!checkingAirPortPickup()) return false;
    if (!checkDestinationOutside()) return false;
    if (!checkingPricing()) return false;
    // if (!checkIsFileSelected()) return false;
    return true;
  };
  const getDetailsFromMetas = (type, metas) => {
    // meta is an array find the key and return the complete object
    let details = null;
    metas?.forEach((item) => {
      if (item.key === type) {
        details = item;
      }
    });
    return details;
  };
  const checkingDocument = () => {
    if (book_state?.serviceType?.value === "self-drive") {
      let driver_licence = getDetailsFromMetas("driver_licence", user?.metas);
      let passport_id = getDetailsFromMetas("passport_id", user?.metas);
      if (driver_licence === null || driver_licence === undefined) {
        toast.error("Please upload your license");
        return false;
      }
      if (passport_id === null || passport_id === undefined) {
        toast.error("Please upload passport or ID Card Upload");
        return false;
      }
      return true;
    }
    return true;
  };
  const handleGuestBookingDetails = (details) => {
    console.log(details);
    setPaymentEmail(details?.email);
    handleConfirm(true, details);
  };
  const checkingLogin = () => {
    // check login user id
    if (user === null || user === undefined || user === "") {
      toast.error("Please login to continue");
      return false;
    }
    return true;
  };
  const handleConfirm = (guest = false, details = null) => {
    console.log("------------");
    console.log(guest);
    if (!checkingDetails()) return;
    if (!guest) {
      console.log("------------");
      if (!checkingLogin()) return;
    }
    let addon_id = [];
    book_state?.selected_addons?.map((item) => {
      addon_id.push({
        ...item,
        quantity: item.count,
      });
    });
    let postData = {
      addon_id: JSON.stringify(addon_id),
      branch_id: book_state?.selectedBranch?.value,
      driver_id: 1,
      duration: 0,
      payment: 0,
      pickup: gettingPickAndDropAddress("pickup"), // this is date
      dropoff: gettingPickAndDropAddress("dropoff"), // this is date

      pickup_addr: book_state?.pickupLocation?.formatted_address,
      dest_addr: book_state?.dropoffLocation.formatted_address,

      final_destination: book_state?.booking_details?.outsideDestination
        ? "Y"
        : "N",
      destination_outside:
        book_state?.booking_details?.outsideDestinationName?.formatted_address,

      airport_pickup: book_state?.booking_details?.airportPickup ? "Y" : "N",
      airport_pickup_details: book_state?.booking_details?.flightNo,
      airport_date: calculateAirportDate(),

      travellers: book_state?.booking_details?.travelers,

      reservation_type: book_state?.rtype,
      hours: book_state?.serviceHourly.value,
      status: true,

      // need modification
      tax_percent: totalTaxValue(),
      // calculate the tax value, tax value is in percentage, so we have to calculate the percentage of the total price in tax_charge

      tax_charge: calculateTax(hourlyPrice(), totalTaxValue()),
      // tax_total: calculateTax(hourlyPrice(), totalTaxValue()) + hourlyPrice(),
      tax_total:
        calculateTax(hourlyPrice(), totalTaxValue()) +
        hourlyPrice() +
        calculateTax(totalAddonPrice(), totalTaxValue()) +
        totalAddonPrice(),

      vehicle_id: book_state?.vehicle_details?.vehicle_id,
      service_type: book_state?.serviceType?.value === "self-value" ? "S" : "C",
      driver_licence: book_state?.booking_details?.licenseFileBase64,
      passport_id: book_state?.booking_details?.passportFileBase64,
    };
    if (guest === true) {
      postData = {
        ...postData,
        email: details?.email,
        name: details?.name,
        phone: details?.phone,
        last_name: details?.last_name,
        guest_post: 1,
      };
      setVisible(false);
    } else {
      postData = {
        ...postData,
        customer_id: user?.id,
        guest_post: 2,
      };
    }
    console.log(postData);
    // currenly on hold because old is working
    const postManData = {
      // addon_id: [],
      // branch_id: book_state?.selectedBranch?.value,
      // driver_id: 0,
      // duration: 1,
      // payment: 0,
      // pickup: gettingPickAndDropAddress('pickup'), // this is date
      // dropoff: gettingPickAndDropAddress('dropoff'), // this is date

      vehicle_id: book_state?.vehicle_details?.vehicle_id,

      // curently using dummy value
      customer_id: 37,
      duration: 1,

      branch_id: book_state?.selectedBranch?.value,
      service_type: book_state?.serviceType?.value === "self-value" ? "S" : "C",
      reservation_type: book_state?.rtype,
      addon_id: JSON.stringify(addon_id),

      pickup: gettingPickAndDropAddress("pickup"), // this is date
      dropoff: gettingPickAndDropAddress("dropoff"), // this is date

      pickup_addr: book_state?.pickupLocation?.formatted_address,
      dest_addr: book_state?.dropoffLocation.formatted_address,

      travellers: book_state?.booking_details?.travelers,

      hours: book_state?.serviceHourly.value,

      tax_percent: totalTaxValue(),
      // calculate the tax value, tax value is in percentage, so we have to calculate the percentage of the total price in tax_charge

      tax_charge: calculateTax(hourlyPrice(), totalTaxValue()),
      // tax_total: calculateTax(hourlyPrice(), totalTaxValue()) + hourlyPrice(),
      tax_total:
        calculateTax(hourlyPrice(), totalTaxValue()) +
        hourlyPrice() +
        calculateTax(totalAddonPrice(), totalTaxValue()) +
        totalAddonPrice(),
      status: true,

      airport_pickup: "N",
      airport_pickup_details: "",
      airport_date: "",

      final_destination: "N",
      destination_outside: "",
      payment: 0,
      driver_id: 0,
    };
    console.log(postData);
    let formData = new FormData();
    for (const [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }
    // console.log(formData.)
    createBooking(
      dispatch,
      postData,
      navigate,
      setBookingStatus,
      setBookingResponse
    );
  };

  // make this function only accept numbers
  const calculateTax = (totalPrice, taxPercentage) => {
    return (totalPrice * taxPercentage) / 100;
  };
  const gettingTaxList = () => {
    if (settings === null || settings === undefined || settings.length === 0)
      return [];
    // check if settings has an object with id 16
    const index = settings.findIndex((item) => item.id === 16);
    if (index === -1) return [];
    const getValues = JSON.parse(settings[index].value);
    // this is object with key value pair, convert this to array
    let data = [];
    for (const [key, value] of Object.entries(getValues)) {
      data.push({
        name: key,
        value: value,
      });
    }
    return data;
  };
  const totalTaxValue = () => {
    let total = 0;
    gettingTaxList().map((item) => {
      total += parseFloat(item?.value);
    });
    return total;
  };
  useEffect(() => {
    console.log(user);
    if (user !== null) {
      setPaymentEmail(user?.email);
    }
  }, [user]);
  const onPaymentSuccess = (r) => {
    console.log(r);
    const postData = {
      api_token: getCookie("token"),
      booking_id: bookingResponse.id,
      reference: r.reference,
    };
    console.log(postData);
    paymentAPI(dispatch, postData, navigate, setPaymentStatus);
  };
  const totalAddonPrice = () => {
    let total = 0;
    book_state?.selected_addons?.map((item) => {
      total += item.price * item.count;
    });
    return total;
  };
  const handleGuestBooking = () => {
    if (!checkingDetails()) return;
    setGuestBooking(true);
    setVisible(true);
  };
  return (
    <>
      <Card className="p-6">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="col-span-1 flex justify-between">
            <p className="text-xl font-bold">Daily Allowed Km (whole day)</p>
            <p className="text-xl font-bold text-right">
              {vehicleDetails?.wdwa_dka}
            </p>
          </div>
          <div className="col-span-1 flex justify-between">
            <p className="text-xl font-bold">
              {book_state?.rtype === 1 ? "Hourly Price" : "Daily Price"}
            </p>
            <p className="text-xl font-bold text-right">
              {convertToDecimal(hourlyPrice())}
            </p>
          </div>
          <div className="col-span-1 flex justify-between">
            <p className="text-xl font-bold">Extra mile Cost</p>
            <p className="text-xl font-bold text-right">
              {convertToDecimal(outsideCost())}
            </p>
          </div>
          <div className="col-span-1 flex justify-between">
            <p className="text-xl font-bold">Airport cost</p>
            <p className="text-xl font-bold text-right">
              {convertToDecimal(airportCost())}
            </p>
          </div>
          <div className="col-span-1 flex justify-between"></div>
          <div className="col-span-1">
            <div className="col-span-1 flex justify-between">
              <p className="text-xl font-bold">Total Tax Price</p>
              <p className="text-xl font-bold text-right">
                {convertToDecimal(calculateTax(hourlyPrice(), totalTaxValue()))}
              </p>
            </div>
            <div className="col-span-1 flex justify-between mt-5">
              <p className="text-xl font-bold">Grand Total</p>
              <p className="text-xl font-bold text-right">
                {convertToDecimal(
                  calculateTax(hourlyPrice(), totalTaxValue()) + hourlyPrice()
                )}
              </p>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-6 mt-12">
        <p className="text-xl font-bold">Addons</p>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
          {book_state?.selected_addons?.map((item, index) => {
            return (
              <div key={index} className="col-span-1 flex justify-between">
                <p className="text-md font-semibold font-weight-200">
                  {item.name}
                </p>
                <p className="text-xl font-bold text-right">
                  <span className="text-sm">
                    {item.price} X {item.count}
                  </span>{" "}
                  = {item.price * item.count}
                </p>
              </div>
            );
          })}
          <div className="col-span-1 flex justify-between">
            <p className="text-md font-semibold font-weight-200">
              Total Addons
            </p>
            <p className="text-xl font-bold text-right">
              <span className="">{convertToDecimal(totalAddonPrice())}</span>
            </p>
          </div>
          <div className="col-span-1 flex justify-between">
            <p className="text-md font-semibold font-weight-200">Total Tax</p>
            <p className="text-xl font-bold text-right">
              <span className="">
                {convertToDecimal(
                  calculateTax(totalAddonPrice(), totalTaxValue())
                )}
              </span>
            </p>
          </div>
          <div className="col-span-1 flex justify-between">
            <p className="text-md font-semibold font-weight-200">Grand Total</p>
            <p className="text-xl font-bold text-right">
              <span className="">
                {convertToDecimal(
                  calculateTax(totalAddonPrice(), totalTaxValue()) +
                    totalAddonPrice()
                )}
              </span>
            </p>
          </div>
        </div>
      </Card>
      <Card className="p-6 mt-12">
        <p className="text-xl font-bold">Tax</p>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
          {gettingTaxList().map((item, index) => {
            return (
              <div key={index} className="col-span-1 flex justify-between">
                <p className="text-md font-semibold font-weight-200">
                  {item?.name} {item?.value}%
                </p>
                <p className="text-xl font-bold text-right">
                  <span className="text-sm">
                    {convertToDecimal(calculateTax(hourlyPrice(), item?.value))}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </Card>
      {/*privacy acceptence for payment*/}
      <div className="flex gap-3 cursor-pointer items-center mt-12">
        <input
          type="checkbox"
          id="remember_account"
          className="remember_check cursor-pointer"
          onChange={(e) => setAcceptPrivacy(e.target.checked)}
        />
        <label
          className="font-text select-none cursor-pointer"
          htmlFor="remember_account"
        >
          I accept the
          <Link to={"/terms"} className="text-blue-500" target={"_blank"}>
            Terms & Conditions - Booking Conditions and Privacy Policy.*
          </Link>
        </label>
      </div>
      <div className="w-full mt-12">
        {/* <div className="col-span-1 flex flex-col items-center justify-start gap-6 md:flex-row md:justify-between mb-3">
          <p className="font-bold text-2xl">Total price</p>
          <Card className="px-16 py-6">
            <p className="font-bold text-2xl">
              {" "}
              ${" "}
              {convertToDecimal(
                calculateTax(hourlyPrice(), totalTaxValue()) +
                  hourlyPrice() +
                  calculateTax(totalAddonPrice(), totalTaxValue()) +
                  totalAddonPrice()
              )}{" "}
            </p>
          </Card>
        </div> */}
        <div className="col-span-1 flex flex-col items-center justify-start gap-6 md:flex-row md:justify-between mb-3">
          <p className="font-bold text-2xl">Total price</p>
          <Card className="px-16 py-6">
            <p className="font-bold text-2xl">
              GH₵ {totalpriceGhcVal !== null ? totalpriceGhcVal : "Loading..."}
            </p>
          </Card>
        </div>
        {calculateTax(hourlyPrice(), totalTaxValue()) + hourlyPrice() > 0 && (
          <>
            {bookingStatus === false && user !== null ? (
              <RButton
                isradius={true}
                isfullwidth={true}
                onClick={handleConfirm}
              >
                <span className="flex w-full justify-center items-center gap-2 px-10">
                  Confirm Booking <BsArrowUpRight className="font-bold" />
                </span>
              </RButton>
            ) : null}
            <ChargePayment
              email={paymentEmail}
              charge={bookingStatus}
              // price={convertToDecimal(
              //   calculateTax(hourlyPrice(), totalTaxValue()) +
              //     hourlyPrice() +
              //     calculateTax(totalAddonPrice(), totalTaxValue()) +
              //     totalAddonPrice()
              // )}
              price={totalpriceGhcVal}
              onPaymentSuccess={onPaymentSuccess}
            />
          </>
        )}
      </div>
      {user === null && (
        <div className="mt-12 flex flex-col sm:flex-row items-center sm:justify-center gap-4">
          <Link
            to="/signin"
            onClick={() => {
              dispatch({
                type: SIGN_IN_TO_CONFIRM_BOOKING,
                payload: true,
              });
            }}
          >
            <RButton isradius={true} isfullwidth={false}>
              {/*  width is 40%*/}
              <span className="flex justify-center items-center gap-2 px-10 width-50">
                Login to Confirm Booking{" "}
                <BsArrowUpRight className="font-bold" />
              </span>
            </RButton>
          </Link>
          {book_state?.serviceType?.value === "chauffeur-driven" && (
            <Link to="#" onClick={handleGuestBooking} className="mt-1 sm:mt-0">
              <RButton isradius={true} isfullwidth={false}>
                <span className="flex justify-center items-center gap-2 px-10 width-50">
                  Guest Booking <BsArrowUpRight className="font-bold" />
                </span>
              </RButton>
            </Link>
          )}
          <GuestBookingModal
            visible={visible}
            setVisible={setVisible}
            handleGuestBookingDetails={handleGuestBookingDetails}
          />
        </div>
      )}
    </>
  );
};

export default Payment;
