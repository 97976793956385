import React, {useEffect} from 'react'
import {FaPlay} from "react-icons/fa";
import {toast} from "react-toastify";
import {Modal, Tabs} from "antd";
import styled from "styled-components";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import RButton from "../../components/RButton";
import {isEmail, toast_options} from "../../utils/constants";

const BModal = styled(Modal)`
  & > .ant-modal-content {
    padding: 0;
    button {
      color: white;
      top: 0px;
      right: 0px;
    }
    .ant-modal-header {
      background-color: #024273;
      padding: 15px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-block: 20px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;

const GuestBookingModal = ({visible, setVisible, booking, setBooking, handleBooking, handleGuestBookingDetails}) => {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false)
    }

    const checkNameEmailBeforeBooking = () => {
        if (name === '') {
            toast.error('Please input name')
            return false
        }
        if (email === '') {
            toast.error('Please input email')
            return false
        }
        if (!isEmail(email)) {
            toast.warn("Incorrect your email format", toast_options);
            return false
        }
        if (phone === '') {
            toast.error('Please input phone')
            return false
        }
        return true
    }
    const confirmGuestBooking = () => {
        if (checkNameEmailBeforeBooking()) {
            console.log('confirmGuestBooking')
            handleGuestBookingDetails({
                name,
                email,
                phone,
                last_name: lastName
            })
        }
    }
    return (
        <BModal
            title={
                <div className="flex items-center gap-5">
                    <p className="font-bold text-xl text-white">Guest booking details</p>
                </div>
            }
            width={"500px"}
            open={visible}
            onCancel={handleCancel}
        >
            <div className="grid w-full grid-cols-1 bg-white">
                <div className="col-span-1">
                    <div className="w-full px-8 py-12 rounded-sm shadow-sm">
                        <div className="grid grid-cols-1 gap-6">
                            <div className="col-span-1">
                                <FormControl fullWidth size="small">
                                    <div className="col-span-1 mt-2">
                                        <TextField label="Name"
                                                   onChange={(e) => setName(e.target.value)}
                                                   value={name}
                                                   fullWidth={true}
                                        />
                                    </div>
                                    <div className="col-span-1 mt-2">
                                        <TextField label="Last name"
                                                   onChange={(e) => setLastName(e.target.value)}
                                                   value={lastName}
                                                   fullWidth={true}
                                        />
                                    </div>
                                    <div className="col-span-1 mt-2">
                                        <TextField label="Email"
                                                   onChange={(e) => setEmail(e.target.value)}
                                                   value={email}
                                                   fullWidth={true}
                                        />
                                    </div>
                                    <div className="col-span-1 mt-2">
                                        <TextField label="Phone"
                                                   onChange={(e) => setPhone(e.target.value)}
                                                   value={phone}
                                                   fullWidth={true}
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-span-1 mt-3">
                            <RButton
                                isradius={true}
                                style={{backgroundColor: "rgba(237,139,0,0.9)"}}
                                onClick={confirmGuestBooking}
                                fullWidth={true}
                            >
                                  <span className="flex items-center justify-center gap-2 px-10 font-normal">
                                    Confirm Guest Booking
                                  </span>
                            </RButton>
                        </div>
                    </div>
                </div>
            </div>
        </BModal>
    )
}

export default GuestBookingModal