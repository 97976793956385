import { toast } from "react-toastify";
import { stringConstant, toast_options } from "../../utils/constants";

import { SET_LOADING } from "../type";
import fmsaxios from "../../utils/fmsaxios";
import {
    fms_vehicle_create,
    get_colors,
    get_maker,
    get_model,
    get_vehicles_list,
    vehicle_update
} from "../../utils/webAPIs";
import axios from "axios";

export const getVehicleVariables = async (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: SET_LOADING, payload: true });
        axios.all([
            fmsaxios.get(get_maker),
            fmsaxios.get(get_model),
            fmsaxios.get(get_colors),
        ]).then(axios.spread((...responses) => {
            const firstResponse = responses[0]
            const secondResponse = responses[1]
            const thirdResponse = responses[2]
            dispatch({ type: SET_LOADING, payload: false });
            resolve({
                makers: firstResponse.data.data,
                models: secondResponse.data.data,
                colors: thirdResponse.data.data
            })
        })).catch(errors => {
            // react on errors.
            console.log(errors)
            dispatch({ type: SET_LOADING, payload: false });
            toast.error(stringConstant.request_error, toast_options);
            reject(errors)
        })
    })
}

export const createVehicleAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: SET_LOADING, payload: true });
        fmsaxios
            .post(
                fms_vehicle_create,
                postData
            )
            .then((res) => {
                console.log(res)
                dispatch({ type: SET_LOADING, payload: false });
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    toast.error(stringConstant.failed_update, toast_options);
                    reject(res.data);
                }
            })
            .catch((err) => {
                dispatch({ type: SET_LOADING, payload: false });
                toast.error(stringConstant.request_error, toast_options);
                reject(err);
            });
    })
}

export const getVehicleListAPI = async (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: SET_LOADING, payload: true });
        fmsaxios.post(
            get_vehicles_list,
            postData
        ).then((res) => {
            dispatch({ type: SET_LOADING, payload: false });
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}

export const getSingleVehicleAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: SET_LOADING, payload: true });
        let url = `/vehicle/${postData.id}/get`
        fmsaxios.post(
            url,
            postData
        ).then((res) => {
            dispatch({ type: SET_LOADING, payload: false });
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}

export const vehicleUpdateAPI = (dispatch, postData) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: SET_LOADING, payload: true });
        fmsaxios.post(
            vehicle_update,
            postData
        ).then((res) => {
            dispatch({ type: SET_LOADING, payload: false });
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            console.log(err)
            reject(err);
        });
    })
}