import RButton from "../../components/RButton";
import { Card, TextField } from "@mui/material";
import { BsArrowUpRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getCookie, toast_options } from "../../utils/constants";
import { toast } from "react-toastify";
import { SET_LOADING } from "../../redux/type";
import { useDispatch } from "react-redux";
import {
  paymentDetailsGet,
  paymentDetailsUpdate,
} from "../../redux/actions/myaccountAction";
import styled from "styled-components";
import Select from "react-select";
const PaymentDetails = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [walletNumber, setWalletNumber] = useState("");
  const [walletName, setWalletName] = useState("");
  const [walletNetwork, setWalletNetwork] = useState("");
  const dispatch = useDispatch();
  const [profileDetailsFromServer, setProfileDetailsFromServer] = useState({});
  const bankList = [
    { value: "Absa Bank Ghana Limited", label: "Absa Bank Ghana Limited" },
    { value: "Access Bank Ghana Plc", label: "Access Bank Ghana Plc" },
    {
      value: "Agricultural Development Bank of Ghana",
      label: "Agricultural Development Bank of Ghana",
    },
    {
      value: "Bank of Africa Ghana Limited",
      label: "Bank of Africa Ghana Limited",
    },
    { value: "CalBank Limited", label: "CalBank Limited" },
    {
      value: "Consolidated Bank Ghana Limited",
      label: "Consolidated Bank Ghana Limited",
    },
    { value: "Ecobank Ghana Limited", label: "Ecobank Ghana Limited" },
    { value: "FBN Bank Ghana Limited", label: "FBN Bank Ghana Limited" },
    {
      value: "Fidelity Bank Ghana Limited",
      label: "Fidelity Bank Ghana Limited",
    },
    {
      value: "First Atlantic Bank Limited",
      label: "First Atlantic Bank Limited",
    },
    { value: "First National Bank Ghana", label: "First National Bank Ghana" },
    { value: "GCB Bank Limited", label: "GCB Bank Limited" },
    {
      value: "Guaranty Trust Bank Ghana Limited",
      label: "Guaranty Trust Bank Ghana Limited",
    },
    {
      value: "National Investment Bank Limited",
      label: "National Investment Bank Limited",
    },
    {
      value: "OmniBSIC Bank Ghana Limited",
      label: "OmniBSIC Bank Ghana Limited",
    },
    { value: "Prudential Bank Limited", label: "Prudential Bank Limited" },
    {
      value: "Republic Bank Ghana Limited",
      label: "Republic Bank Ghana Limited",
    },
    {
      value: "Société Générale Ghana Limited",
      label: "Société Générale Ghana Limited",
    },
    {
      value: "Stanbic Bank Ghana Limited",
      label: "Stanbic Bank Ghana Limited",
    },
    {
      value: "Standard Chartered Bank Ghana Limited",
      label: "Standard Chartered Bank Ghana Limited",
    },
    {
      value: "United Bank for Africa Ghana Limited",
      label: "United Bank for Africa Ghana Limited",
    },
    {
      value: "Universal Merchant Bank Limited",
      label: "Universal Merchant Bank Limited",
    },
    { value: "Zenith Bank Ghana Limited", label: "Zenith Bank Ghana Limited" },
  ];
  const mobileNetworks = [
    { value: "MTN", label: "MTN" },
    { value: "Airtel/Tigo", label: "Airtel/Tigo" },
    { value: "Vodafone", label: "Vodafone" },
  ];
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(
      accountNumber,
      accountName,
      bankName,
      bankBranch,
      walletNumber,
      walletName,
      walletNetwork
    );
    if (accountNumber === "") {
      toast.warn("Please add your bank account number", toast_options);
      return;
    }
    if (accountName === "") {
      toast.warn("Please add your bank account name", toast_options);
      return;
    }
    if (bankName === "") {
      toast.warn("Please add your bank name", toast_options);
      return;
    }
    if (bankBranch === "") {
      toast.warn("Please add your bank branch", toast_options);
      return;
    }
    if (walletNumber === "") {
      toast.warn("Please add your wallet number", toast_options);
      return;
    }
    if (walletName === "") {
      toast.warn("Please add your wallet name", toast_options);
      return;
    }
    if (walletNetwork === "") {
      toast.warn("Please add your wallet network", toast_options);
      return;
    }
    const data = {
      bankAccountNumber: accountNumber,
      bankAccountName: accountName,
      bankName: bankName,
      bankBranch: bankBranch,
      mobileWalletNumber: walletNumber,
      mobileWalletName: walletName,
      mobileWalletNetwork: walletNetwork,
      api_token: getCookie("token"),
    };
    dispatch({ type: SET_LOADING, payload: true });
    paymentDetailsUpdate(dispatch, data);
  };
  useEffect(() => checkPaymentDetails(), []);
  const checkPaymentDetails = () => {
    dispatch({ type: SET_LOADING, payload: true });
    paymentDetailsGet(dispatch, {
      api_token: getCookie("token"),
    })
      .then((r) => {
        if (r.status === "success") {
          setProfileDetailsFromServer(r.data);
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (
      profileDetailsFromServer !== undefined &&
      profileDetailsFromServer !== null
    ) {
      if (Object.keys(profileDetailsFromServer).length > 0) {
        initialize(profileDetailsFromServer);
      }
    }
  }, [profileDetailsFromServer]);
  const initialize = (details) => {
    setAccountNumber(details?.bankAccountNumber);
    setAccountName(details?.bankAccountName);
    setBankName(details?.bankName);
    setBankBranch(details?.bankBranch);
    setWalletNumber(details?.mobileWalletNumber);
    setWalletName(details?.mobileWalletName);
    setWalletNetwork(details?.mobileWalletNetwork);
  };
  const MSelect = styled(Select)`
    height: 53px;
    width: 100%;
    z-index: 1;
    & > div:first-of-type {
      height: 100%;
      width: 100%;
    }
  `;
  return (
    <>
      <div className="flex flex-col w-full gap-2 py-6">
        <p className="text-xl font-bold">Payment Details</p>
        <p className="text-sm font-text">Dashboard - Payment Details</p>
      </div>
      <Card className="flex flex-col w-full gap-6 p-6 md:w-2/3">
        <div className="grid w-full grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="flex flex-col col-span-1 gap-6">
            <p className="text-base font-bold">Bank Account Details</p>
            <TextField
              fullWidth
              label="Bank Account Number"
              variant="outlined"
              size="small"
              onChange={(e) => setAccountNumber(e.target.value)}
              value={accountNumber}
            />
            <TextField
              fullWidth
              label="Bank Account Name"
              variant="outlined"
              size="small"
              onChange={(e) => setAccountName(e.target.value)}
              value={accountName}
            />
            {/*<TextField*/}
            {/*  fullWidth*/}
            {/*  label="Bank Name"*/}
            {/*  variant="outlined"*/}
            {/*  size="small"*/}
            {/*  onChange={(e) => setBankName(e.target.value)}*/}
            {/*  value={bankName}*/}
            {/*/>*/}
            <MSelect
              options={bankList}
              onChange={(e) => setBankName(e.value)}
              value={bankList.find((item) => item.value === bankName)}
            />
            <TextField
              fullWidth
              label="Bank Branch"
              variant="outlined"
              size="small"
              onChange={(e) => setBankBranch(e.target.value)}
              value={bankBranch}
              className={"z-0"}
            />
          </div>
          <div className="flex flex-col col-span-1 gap-6">
            <p className="text-base font-bold">Mobile Wallet Details</p>
            <TextField
              fullWidth
              label="Mobile Wallet Number"
              variant="outlined"
              size="small"
              onChange={(e) => setWalletNumber(e.target.value)}
              value={walletNumber}
            />
            <TextField
              fullWidth
              label="Mobile Wallet Name"
              variant="outlined"
              size="small"
              onChange={(e) => setWalletName(e.target.value)}
              value={walletName}
            />
            {/*<TextField*/}
            {/*  fullWidth*/}
            {/*  label="Mobile Wallet Network"*/}
            {/*  variant="outlined"*/}
            {/*  size="small"*/}
            {/*  onChange={(e) => setWalletNetwork(e.target.value)}*/}
            {/*  value={walletNetwork}*/}
            {/*/>*/}
            <MSelect
              options={mobileNetworks}
              onChange={(e) => setWalletNetwork(e.value)}
              value={mobileNetworks.find(
                (item) => item.value === walletNetwork
              )}
            />
          </div>
        </div>
        <div className="flex justify-end w-full">
          <RButton
            isradius={true}
            style={{ backgroundColor: "rgba(237,139,0,0.9)" }}
            onClick={onSubmit}
          >
            <span className="flex items-center justify-center w-full gap-2 px-4 text-base font-normal">
              Save
              <BsArrowUpRight className="font-bold" />
            </span>
          </RButton>
        </div>
      </Card>
    </>
  );
};

export default PaymentDetails;
