import React from "react";
import GooglePlayButton from "./../../components/GooglePlayButton";
import AppleButton from "./../../components/AppleButton";

export default function Footer() {
  return (
    <div className="flex justify-center">
      <div className="w-[80vw]">
        <footer className="text-white">
          <div className="container mx-auto py-10 grid grid-cols-1 lg:grid-rows-2 gap-8">
            {/* Quick Links Section */}
            <div className="flex flex-col items-center justify-center space-y-6">
              <h4 className="text-xl font-bold text-center">Quick Links</h4>
              <ul className="flex flex-col lg:flex-row lg:space-x-10 space-y-4 lg:space-y-0 text-center">
                <li>
                  <a className="hover:underline" href="/aboutus">
                    About us
                  </a>
                </li>
                <li>
                  <a className="hover:underline" href="/services">
                    Services
                  </a>
                </li>
                <li>
                  <a className="hover:underline" href="/thirdparty">
                    Thirdparty
                  </a>
                </li>
                <li>
                  <a className="hover:underline" href="/contactus">
                    Contact
                  </a>
                </li>
              </ul>
            </div>

            {/* Quick Download Forms Section */}
            <div className="md:col-span-2 lg:col-span-3 flex justify-center items-center rounded-lg">
              <div className="w-full">
                <h4 className="text-xl font-bold mb-6 text-center">
                  Quick Download Forms
                </h4>
                <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 lg:justify-center">
                  <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4 w-full lg:w-auto">
                    <img src="image/download.svg" alt="Download" />
                    <div className="px-2 border-l border-white/40 text-sm text-left">
                      <a
                        target="_blank"
                        href="/assets/Third_Party_Vehicle_Amendment_Form_11.23.23.pdf"
                      >
                        <p className="text-white">Third-party Vehicle</p>
                        <p className="text-white">Delivery Form</p>
                      </a>
                    </div>
                  </button>
                  <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4 w-full lg:w-auto">
                    <img src="image/download.svg" alt="Download" />
                    <div className="px-2 border-l border-white/40 text-sm text-left">
                      <a href="/assets/THIRD_PARTY_VEHICLE_OWNER_AGREEMENT_2023.pdf">
                        <p className="text-white">Third-party Vehicle</p>
                        <p className="text-white">Ownership Agreement</p>
                      </a>
                    </div>
                  </button>
                  <button className="bg-black border border-white rounded-lg text-white p-2 px-4 flex items-center gap-4 w-full lg:w-auto">
                    <img src="image/download.svg" alt="Download" />
                    <div className="px-2 border-l border-white/40 text-sm text-left">
                      <a href="/assets/NEW_INSPECTION_HANDOVER_CH_CK_LIST_AND_DRIVER_ACTIVITY_LOG.pdf">
                        <p className="text-white">Third-party Vehicle</p>
                        <p className="text-white">Checklist</p>
                      </a>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
