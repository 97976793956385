import React from 'react'
import LicenceComponent from "./LicenceComponent";

const LicencePage = () => {
    return (
        <>
            <div className="flex flex-col w-full gap-2 py-6">
                <p className="text-xl font-bold">My Licences</p>
                <p className="text-sm font-text">Dashboard - Edit Licences</p>
            </div>
            <div className="grid w-full grid-cols-1 bg-white lg:grid-cols-3">
                <div className="col-span-3">
                    <div className="w-full px-8 py-12 rounded-sm shadow-sm">
                        <div className="grid grid-cols-1 gap-6">
                            <div className="col-span-1">
                                <LicenceComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LicencePage