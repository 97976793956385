import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import AppRoutes from "./AppRoutes";
import { store } from "./redux/store";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import BookingDetails from "./components/BookingDetails";
const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
};
function App() {
  const pages = useRoutes(AppRoutes);

  return (
    <>
      <Provider store={store}>
        <ScrollToTop />
        {pages}
      </Provider>
      </>
  )
}

export default App;
