import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const PageNotFound = () => {
    return (
        <>
            <div className="w-full bg-[var(--main-color2)] relative">
                {/*add radious top right and left*/}
                <div className="max-w-[1280px] w-[90%] h-[300] flex content-center justify-center m-auto grid-cols-1 overflow-hidden gap-16 pt-12 pb-24">
                    <img src={'/image/404.png'} className={''} alt={'404'}/>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
