import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment/moment";
import { TbClockShare } from "react-icons/tb";
import { BsArrowUpRight, BsCalendar4Event } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import ButtonSelect from "./ButtonSelect";
import RButton from "./RButton";
import { SET_BOOKING_STEP_0 } from "../redux/type";
import ButtonTimePicker from "./ButtonTimePicker";
import ButtonDatePicker from "./ButtonDatePicker";

const BookingModalNew = ({ visible, onClose }) => {
  const book_state = useSelector((state) => state.book);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [isDaily, setIsDaily] = useState(true);
  const [startdate, setStartDate] = useState(book_state?.startdate);
  const [starttime, setStartTime] = useState(book_state?.starttime);
  const [pickupLocation, setPickupLocation] = useState(
    book_state?.pickupLocation
  );
  const [dropoffLocation, setDropoffLocation] = useState(
    book_state?.dropoffLocation
  );
  const [selectedOption, setSelectedOption] = useState(book_state?.serviceType);
  const [selectedHours, setSelectedHours] = useState(book_state?.serviceHourly);
  const BookNowButtonHandler = () => {
    const payload = {
      startdate,
      starttime,
      pickupLocation,
      dropoffLocation,
      serviceType: selectedOption,
    };
    console.log("Dispatching:", payload);
    dispatch({ type: SET_BOOKING_STEP_0, payload });
    navigation("/booking");
    onClose();
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white w-[90vw] md:w-[80vw] lg:w-[60vw] xl:w-[50vw] max-w-6xl mx-auto rounded-lg shadow-lg p-6 space-y-6">
        <button
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="space-y-6">
          <div className="flex justify-center">
            <button
              className={`p-4 text-sm font-semibold text-center rounded-tl-lg ${
                isDaily ? "bg-[#024273] text-white" : "bg-[#FDEEEC] text-black"
              }`}
              onClick={() => setIsDaily(true)}
            >
              Daily Rental
            </button>
            <button
              className={`p-4 text-sm font-semibold text-center rounded-tr-lg ${
                !isDaily ? "bg-[#024273] text-white" : "bg-[#FDEEEC] text-black"
              }`}
              onClick={() => setIsDaily(false)}
            >
              Hourly Rental
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <ButtonDatePicker
              onDateChange={(value) => setStartDate(value)}
              value={startdate}
              dvalue={startdate}
              icon={<BsCalendar4Event />}
              title={"Date"}
            />
            <ButtonTimePicker
              onTimeChange={(value) => {
                setStartTime(moment(value?.format("YYYY-MM-DD HH:mm:ss")));
              }}
              value={starttime}
              icon={<TbClockShare />}
              defaultValue={moment(starttime).format("HH:mm:ss")}
              title={"Time"}
            />
            <ButtonSelect
              label={"image/home/from.svg"}
              options={null}
              value={pickupLocation}
              onOptionChange={(value) => setPickupLocation(value)}
              title={"From"}
            />
            <ButtonSelect
              label={"image/home/to.svg"}
              options={null}
              value={dropoffLocation}
              onOptionChange={(value) => setDropoffLocation(value)}
              title={"To"}
            />
          </div>

          <div className="space-y-4">
            <Select
              className="border border-black rounded-lg w-full"
              options={[
                { label: "Self-Drive", value: "self-drive" },
                { label: "Chauffeur Drive", value: "chauffeur-driven" },
              ]}
              value={selectedOption?.value}
              onChange={(value, option) => setSelectedOption(option)}
              placeholder="Select option"
            />

            {!isDaily && (
              <Select
                options={[
                  { value: "1", label: "1 Hr" },
                  { value: "2", label: "2 Hr" },
                  { value: "3", label: "3 Hr" },
                  { value: "4", label: "4 Hr" },
                  { value: "5", label: "5 Hr" },
                ]}
                value={selectedHours?.value}
                onChange={(value, option) => setSelectedHours(option)}
                placeholder="Select hours"
              />
            )}
          </div>

          <div className="flex justify-center pt-4">
            <RButton isradius={true} onClick={BookNowButtonHandler}>
              <span className="flex items-center justify-center gap-2 w-full text-lg font-semibold">
                Book Now
                <BsArrowUpRight />
              </span>
            </RButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModalNew;
