import { toast } from "react-toastify";
import {
  getCookie, multipleErrors,
  setCookie,
  stringConstant,
  toast_options,
} from "../../utils/constants";
import {
    signup_corporate,
    signup_individual,
    signin,
    validate_token, fms_register, fms_login, fms_profile, forgot_password,
} from "../../utils/webAPIs";
import yaxios from "../../utils/yaxios";
import { SET_LOADING, SIGN_IN, LOG_OUT } from "../type";
import fmsaxios from "../../utils/fmsaxios";

export const signUp = (dispatch, postData, accountType, navigate) => {
  fmsaxios
    .post(
        fms_register,
        postData
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === "success") {
          toast.success(res.data.message, toast_options);
          navigate("/signin");
        } else {
          toast.warning(res.data.message, toast_options);
        }
      } else {
        toast.error(stringConstant.failed_register, toast_options);
      }
      dispatch({ type: SET_LOADING, payload: false });
      console.log(res)
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, payload: false });
      multipleErrors(err.response.data)
    });
};

export const signIn = (dispatch, postData, navigate, toBooking = false) => {
  fmsaxios
    .post(fms_login, postData)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === "failed") {
          toast.error(res.data.message, toast_options);
        } else {
          toast.success(stringConstant.success_login, toast_options);
          dispatch({ type: SIGN_IN, payload: res.data.data });
          setCookie("token", res.data.api_token);
          if (toBooking) navigate("/booking?login=true", {something: true}, {my: '1'});
          else navigate("/dashboard");
        }
      } else toast.error(stringConstant.failed_login, toast_options);
      dispatch({ type: SET_LOADING, payload: false });
      console.log(res)
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: SET_LOADING, payload: false });
      toast.error(stringConstant.request_error, toast_options);
    });
};

export const getInfoByToken = (dispatch, isVerify = false, navigate) => {
  fmsaxios
    .post(fms_profile, {
        api_token: getCookie("token"),
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === "failed") {
          if (getCookie("token").length > 0)
            toast.error(res.data.message, toast_options);
          dispatch({ type: LOG_OUT, payload: null });
          if (isVerify) navigate("/signin");
        } else {
          dispatch({ type: SIGN_IN, payload: res.data.data });
        }
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        toast.error(stringConstant.request_error, toast_options);
        dispatch({ type: LOG_OUT, payload: null });
        if (isVerify) navigate("/signin");
      }
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: LOG_OUT, payload: null });
    });
};


export const forgotPassword = (dispatch, postData) => {
    dispatch({ type: SET_LOADING, payload: true });
    fmsaxios
        .post(
            forgot_password,
            postData
        )
        .then((res) => {
            if (res.status === 200) {
                if (res.data.status === "success") {
                    toast.success(res.data.message, toast_options);
                } else if (res.data.status === 1) {
                    toast.success(res.data.message, toast_options);
                } else {
                    toast.warning(res.data.message, toast_options);
                }
            } else {
                toast.error(stringConstant.failed_register, toast_options);
            }
            dispatch({ type: SET_LOADING, payload: false });
        })
        .catch((err) => {
            dispatch({ type: SET_LOADING, payload: false });
            multipleErrors(err.response.data)
        });
};
