import React, { useState } from "react";
import { TimePicker } from "antd";
import styled from "styled-components";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import moment from "moment";
import {TbClockShare} from "react-icons/tb";
const MTimePicker = styled(TimePicker)`
  & > .ant-picker-input {
    display: none;
  }
`;
dayjs.extend(customParseFormat)
const ButtonTimePicker = ({ onTimeChange, icon, tvalue, defaultValue, value, title }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (open) => {
    setOpen(open);
  };

  const handleChange = (valuea) => {
    onTimeChange(valuea);
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
      <div className="rounded-lg p-2 px-4 pr-6 flex items-center justify-start mt-2 border border-r-gray-300">
        <div className="bg-white rounded-md p-2">
          <div
              onClick={handleClick}
              className="cursor-pointer rounded-sm w-8 h-8 flex items-center justify-center relative"
          >
            <img src="image/home/timepicker.svg" />
            <MTimePicker
                open={open}
                onOpenChange={handleOpenChange}
                onChange={handleChange}
                bordered={false}
                suffixIcon={null}
                value={tvalue}
                defaultValue={
                  defaultValue ? dayjs(defaultValue, 'HH:mm') :
                      dayjs('0:0', 'HH:mm')
                }
                format={'HH:mm'}
            />
          </div>
        </div>
        <div className="flex-1 h-full flex flex-col justify-between">
          <p className="text-[#333]">{title}</p>
          <p className="text-[var(--text-color)] font-bold" onClick={handleClick}>
            {value?.format("hA : mm")}
          </p>
        </div>
      </div>
  )
};

export default ButtonTimePicker;
