import axios from 'axios';
import { getCookie } from './constants';
import { FMS_BACKEND_URL } from './config';

// fms setup
const fmsaxios = axios.create({
    baseURL: FMS_BACKEND_URL,
});
// fms setup


// fms setup
fmsaxios.interceptors.request.use(
    (config) => {
        let cookie = getCookie("token");
        // config.headers["Content-Type"] =
        //     config.data && config.data instanceof FormData
        //         ? "multipart/form-data"
        //         : "application/json";
        // config.headers["Accept"] = "application/json";
        let newcookie = cookie ? cookie : "";
        if (newcookie.length > 0) {
            config.headers["Authorization"] = newcookie;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// fms setup

export default fmsaxios;
