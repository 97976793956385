import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Faq = () => {
    return (
        <>
            <div className="min-h-[500px] w-full bg-[var(--main-color2)] relative flex items-center justify-center">
                <h4 className="text-white">Faq page</h4>
            </div>
        </>
    )
}

export default Faq
