import React, {useEffect} from 'react'
import {FaPlay} from "react-icons/fa";
import {toast} from "react-toastify";
import {Modal, Tabs} from "antd";
import styled from "styled-components";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import RButton from "../../../components/RButton";
import {BsArrowUpRight} from "react-icons/bs";
const { TabPane } = Tabs;
const BModal = styled(Modal)`
  & > .ant-modal-content {
    padding: 0;
    button {
      color: white;
      top: 0px;
      right: 0px;
    }
    .ant-modal-header {
      background-color: #024273;
      padding: 15px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-block: 20px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;

const CancelModal = ({ visible, setVisible, item, onCancelPress, cancelList }) => {
    const [reason, setReason] = React.useState('')
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setReason('')
        setVisible(false)
    }
    useEffect(() => {
        console.log(cancelList)
        if (!visible) {
            setReason('')
        }
    }, [visible]);
    const cancelBooking = () => {
        if (reason === '') {
            toast.error('Please select a reason')
            return
        }
        onCancelPress(item.id, reason)
    }
    return (
        <BModal
            title={
                <div className="flex items-center gap-5">
                    <p className="font-bold text-xl text-white">Cancel Booking</p>
                </div>
            }
            width={"500px"}
            open={visible}
            onCancel={handleCancel}
        >
            <div className="grid w-full grid-cols-1 bg-white">
                <div className="col-span-1">
                    <div className="w-full px-8 py-12 rounded-sm shadow-sm">
                        <div className="grid grid-cols-1 gap-6">
                            <div className="col-span-1">
                                <FormControl fullWidth size="small">
                                    <InputLabel>Reason</InputLabel>
                                    <Select
                                        label="Reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    >
                                        {cancelList?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.reason}>
                                                    {item.reason}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-span-1">
                                <RButton
                                    isradius={true}
                                    style={{backgroundColor: "rgba(237,139,0,0.9)"}}
                                    onClick={cancelBooking}
                                >
                                  <span className="flex items-center justify-center gap-2 px-10 font-normal">
                                    Cancel Booking
                                  </span>
                                </RButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BModal>
    )
}

export default CancelModal