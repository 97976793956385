import React from 'react'
import {SlLocationPin, SlPhone} from "react-icons/sl";
import {GoMail} from "react-icons/go";
import {GoogleMap, Marker} from "@react-google-maps/api";
import RButton from "../../components/RButton";
import {BsArrowUpRight} from "react-icons/bs";
import data from "./data";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Terms = () => {
    return (
        <>
            <div className="w-full bg-[var(--main-color2)] relative">
                {/*add radious top right and left*/}
                <div className="max-w-[1280px] w-[90%] m-auto grid grid-cols-1 overflow-hidden gap-16 pt-12 pb-24">
                    <Accordion allowZeroExpanded>
                        {
                            data.map((item, index) => {
                                return (
                                    <AccordionItem key={index}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{
                                                backgroundColor: '#f18e02', color: 'white'
                                            }}>
                                                {item.name}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel style={{background: 'white'}}>
                                             <div style={{lineHeight:"30px"}} dangerouslySetInnerHTML={{ __html: item.value }} />
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                )
                            })
                        }
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default Terms
