import { FaArrowLeft} from "react-icons/fa6";
import { FaArrowRight} from "react-icons/fa6";
import {useSelector} from "react-redux";

const VehicalPageSlider = ({onNextPage}) => {
  const vehiclesByTypePagination = useSelector(
      (state) => state.globalData?.vehiclesByTypePagination
  );
  const calculatingPages = () => {
    let pages = [];
    let currentPage = vehiclesByTypePagination?.current_page;
    let lastPage = vehiclesByTypePagination?.last_page;
    let startPage = currentPage - 3;
    let endPage = currentPage + 3;

// Ensure that startPage is not less than 1 and endPage is not more than lastPage
    startPage = Math.max(startPage, 1);
    endPage = Math.min(endPage, lastPage);

// Adjust startPage and endPage if there aren't enough pages
    if (currentPage - startPage < 3) {
      endPage = Math.min(endPage + (3 - (currentPage - startPage)), lastPage);
    }
    if (endPage - currentPage < 3) {
      startPage = Math.max(startPage - (3 - (endPage - currentPage)), 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }
  const Button = (props) => {
    const {text,} = props
    return (
      <button {...props} className="w-[55px] h-[55px] text-center border border-gray-200 hover:bg-gray-200 flex items-center justify-center">{text}</button>
    )
  }
  return (
    <div className='container max-w-[600px] mx-auto'>
      <div className='flex flex-row w-[300px] h-[55px] mx-auto  rounded mb-4'>
        <Button text={<FaArrowLeft/>} onClick={() => {
          let page = vehiclesByTypePagination.current_page - 1;
            if (page < 1) {
                page = 1;
            }
            onNextPage(page)
        }}/>
        {calculatingPages().map((page) => {
            return <Button text={page} key={page} onClick={() => {
                console.log(page)
                onNextPage(page)
             }} style={page === vehiclesByTypePagination.current_page ? {backgroundColor: '#024273', color: 'white'} : {}}
            />
        })}
        {calculatingPages().length === 0 && (
            <Button text={1} key={1} onClick={() => {
                console.log(1)
                onNextPage(1)
            }} />
        )}
        <Button text={<FaArrowRight/>} onClick={() => {
            let page = vehiclesByTypePagination.current_page + 1;
                if (page > vehiclesByTypePagination.last_page) {
                    page = vehiclesByTypePagination.last_page;
                }
                onNextPage(page)

        }}/>
      </div>
    </div>
  )
}

export default VehicalPageSlider;
