import React from "react";

const TimelineComponent = () => {
  return (
    <>
      <div className="w-full bg-[#024273] h-28">
        <div className="w-[90%] container mx-auto max-w-7xl h-full flex items-center">
          <p className="text-white text-2xl lg:text-5xl">
            Earn Passive Income with YOKS WaraDrive 
          </p>
        </div>
      </div>
      <div
        className="flex flex-col md:flex-row items-center justify-center bg-cover bg-center relative"
        style={{ backgroundImage: "url('image/home/MapBG.jpg')" }}
      > 
        <div className="absolute w-full h-full top-0 left-0 bg-white/50"></div>
        <div className="md:w-1/2 p-8 flex justify-center sm:justify-end">
          <div className="w-full md:w-3/4">
            <div className="text-3xl mb-8 font-bold text-white">
              How It Works
            </div>
            <div className="grid grid-rows-3 max-w-md">
              <div className="flex mt-3 row-span-1">
                <div className="flex gap-3 flex-col items-center ">
                  <div className="w-4 h-4  rounded-full border-2 border-gray-300"></div>
                  <div className="h-[88px] w-0.5 bg-gray-300"></div>
                </div>
                <div
                  className="ml-2 text-gray-300 w-full"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <h2 className="text-[#AE002B] text-xl font-bold mb-2 transition duration-300 ease-in-out transform hover:scale-110">
                    Register your vehicle  with YOKS
                  </h2>
                  <p className="text-sm transition duration-300 ease-in-out transform hover:scale-110 text-black rounded-md bg-white p-5 pr-8 w-full">
                    Vehicle owner registers their vehicle with YOKS to participate in 3rd Party car rental service
                  </p>
                </div>
              </div>
              <div className="flex mt-3 row-span-1">
                <div className="flex gap-3 flex-col items-center ">
                  <div className="w-4 h-4  rounded-full border-2 border-gray-300"></div>
                  <div className="h-[88px] w-0.5 bg-gray-300"></div>
                </div>
                <div
                  className="ml-2  text-gray-300 w-full"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <h2 className="text-[#AE002B] text-xl font-bold mb-2 transition duration-300 ease-in-out transform hover:scale-110 w-full">
                    Vehicle rented out on YOKS platform
                  </h2>
                  <p className="text-sm transition duration-300 ease-in-out transform hover:scale-110 text-black rounded-md bg-white p-5 pr-8">
                    Vehicle owner gives vehicle access to YOKS to be rented out daily.
                  </p>
                </div>
              </div>
              <div className="flex mt-3 row-span-1">
                <div className="flex gap-3 flex-col items-center ">
                  <div className="w-4 h-4  rounded-full border-2 border-gray-300"></div>
                  <div className="h-[88px] w-0.5 bg-gray-300"></div>
                </div>
                <div
                  className="ml-2  text-gray-300 w-full"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <h2 className="text-[#AE002B] text-xl font-bold mb-2 transition duration-300 ease-in-out transform hover:scale-110">
                    Revenue Sharing Program
                  </h2>
                  <p className="text-sm transition duration-300 ease-in-out transform hover:scale-110 text-black rounded-md bg-white p-5 pr-8 w-full">
                    Vehicle's are rented out on a revenue sharing basis
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 relative top-16 ">
          <img
            className="w-[70%] mt-12 my-12 sm:ml-auto"
            src="image/home/car1.png"
            alt="Your Image"
          />
        </div>
      </div>
    </>
  );
};

export default TimelineComponent;
