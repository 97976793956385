import { useEffect, useState } from "react";
import { Card, TextField } from "@mui/material";
import Counter from "../../components/Counter";
import RButton from "../../components/RButton";
import { BsArrowUpRight } from "react-icons/bs";
import styled from "styled-components";
import Select from "react-select";
import fmsaxios from "../../utils/fmsaxios";
import { addonList } from "../../utils/webAPIs";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADING, SET_SELECTED_ADDONS } from "../../redux/type";
import CounterWrapper from "./Component/CounterWrapper";
import { getAddonData } from "../../redux/actions/bookingAction";

const AddOn = ({ onStep }) => {
  const [childSeatCount, setChildSeatCound] = useState(0);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [addonLists, setAddonLists] = useState([]);
  const [selectedAddonList, setSelectedAddonList] = useState([]);
  const addonListsStore = useSelector((state) => state.book?.addons);
  const selected_addons = useSelector((state) => state.book?.selected_addons);
  const dispatch = useDispatch();
  const addonOptions = [
    { value: "Tours", label: "Tours" },
    { value: "Extras", label: "Extras" },
    { value: "Amenities", label: "Amenities" },
    { value: "Tickets", label: "Tickets" },
    { value: "Apartment", label: "Apartment" },
  ];
  const MSelect = styled(Select)`
    height: 53px;
    width: 100%;
    & > div:first-of-type {
      height: 100%;
      width: 100%;
    }
  `;
  const gettingAddonData = (e) => {
    if (e.value === selectedAddon) return;
    setSelectedAddon(e.value);
    getAddonData(dispatch, e.value);
    // dispatch({type: SET_LOADING, payload: true});
    // fmsaxios.post(addonList, {type: e.value}).then((res) => {
    //   dispatch({type: SET_LOADING, payload: false});
    //   console.log(res);
    //   if (res.status) {
    //     setAddonLists(res.data.data);
    //   }
    // }).catch((err) => {
    //   dispatch({type: SET_LOADING, payload: false});
    // })
  };
  const counterChanged = (count, addon) => {
    console.log(count, addon);
    if (count > 0) {
      // dont add new update count only
      const index = selectedAddonList.findIndex((item) => item.id === addon.id);
      if (index === -1) {
        setSelectedAddonList([...selectedAddonList, { ...addon, count }]);
      } else {
        const temp = [...selectedAddonList];
        temp[index].count = count;
        setSelectedAddonList(temp);
      }
    } else {
      setSelectedAddonList(
        selectedAddonList.filter((item) => item.id !== addon.id)
      );
    }
  };
  useEffect(() => {
    console.log(addonListsStore);
    if (addonListsStore) {
      setAddonLists([]);
      setTimeout(() => {
        setAddonLists(addonListsStore);
      }, 100);
    }
  }, [addonListsStore]);
  const clickContinue = () => {
    console.log(selectedAddonList);
    console.log(selected_addons);
    dispatch({ type: SET_SELECTED_ADDONS, payload: selectedAddonList });
    onStep(3);
  };
  useEffect(() => {
    console.log("testing");
    setSelectedAddonList(selected_addons);
  }, []);
  useEffect(() => {
    console.log(selectedAddonList);
  }, [selectedAddonList]);
  useEffect(() => {
    if (selectedAddon === null) {
      setSelectedAddon(addonOptions[0].value);
      gettingAddonData(addonOptions[0]);
    }
  }, []);
  const getDefaultValue = (item) => {
    const index = selectedAddonList.findIndex((addon) => addon.id === item.id);
    if (index === -1) return 0;
    return selectedAddonList[index].count;
  };
  return (
    <>
      <p className="font-bold text-3xl">Addon-Options</p>
      <MSelect
        options={addonOptions}
        onChange={(e) => gettingAddonData(e)}
        value={addonOptions.find((item) => item.value === selectedAddon)}
      />
      <Card className="mt-4 p-6">
        <div className="w-full mt-2 grid grid-cols-1 divide-y">
          {addonLists.map((item, index) => {
            return (
              <div
                key={index}
                className="col-span-1 py-6 flex items-center justify-between"
              >
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 items-center">
                    <p className="text-[var(--text-color)] font-bold text-2xl">
                      {item.name}
                    </p>
                    <div className="y_badge">$ {item.price}</div>
                  </div>
                  <p className="text-[#9E8F8F] text-sm font-text">
                    {item.description}
                  </p>
                </div>
                <CounterWrapper
                  onChange={counterChanged}
                  addon={item}
                  defaultValue={getDefaultValue(item)}
                />
              </div>
            );
          })}
          {addonLists.length === 0 && (
            <div className="col-span-1 py-6 flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4 items-center">
                  <p className="text-[var(--text-color)] font-bold text-2xl">
                    No Addon
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <div className="w-full mt-12">
        <RButton isradius={true} isfullwidth={true} onClick={clickContinue}>
          <span className="flex w-full justify-center items-center gap-2 px-10">
            Continue <BsArrowUpRight className="font-bold" />
          </span>
        </RButton>
      </div>
    </>
  );
};

export default AddOn;
