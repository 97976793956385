import BreadCrumb from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { FiCheck } from "react-icons/fi";

const ServiceCorperatefleet = () => {
  return (
    <div>
      <div className="w-full">
        <BreadCrumb title="Corperate Fleet Management">
          <Link to="/">Home</Link> - <Link to="#">Services</Link>
        </BreadCrumb>
      </div>
      <div className="relative w-full">
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="max-w-[1280px] w-[90%] h-full relative m-auto">
            <div className="absolute right-0 top-4 w-max h-max">
              {/* <RButton isradius={true}>
                <span className="flex items-center gap-4 px-10">
                  <FaPlay />
                  Book Now
                </span>
              </RButton> */}
            </div>
          </div>
        </div>
        <img
          src="image/services/corperatefleet_banner.png"
          className="w-full"
          alt=""
        />
      </div>
      <div className="w-full">
        <div className="max-w-[1280px] w-[90%] m-auto py-8">
          <h1 className="text-[44px] text-[var(--text-color)]">
            Corporate Fleet Management
          </h1>
          <p className="text-[var(--text-color)] mt-6 font-text">
            We provide the best value for money transportation solutions for
            businesses in Ghana. We understand the complexities and challenges
            faced by companies in purchasing and managing a large fleet of
            vehicles. Thus, we have technical capacity and managerial
            competencies in fleet management—from maintenance to tracking
            expenses, which will ensure that we assist in delivering the
            appropriate transportation solutions to clients.
          </p>
          <p className="text-[var(--text-color)] mt-6">
            We evaluate the business needs and requirements and provide the
            right vehicles on an operating lease basis. The typical lease is 36
            months; however, the duration period varies from client to client.
            We can arrange buy-back options with the client to purchase
            vehicles.
          </p>
          <img
            src="image/services/corperatefleet_thumbnail1.svg"
            className="w-full mt-6"
            alt=""
          />
          <h1 className="text-[44px] text-[var(--text-color)] mt-6 font-semibold">
            Our Services:
          </h1>
          <div className="text-[var(--text-color)]">
            <ul className="list-disc pl-7 font-text">
              <li>
                Vehicle Maintenance: We handle all the maintenance needs of
                vehicles, from regular check-ups to emergency repairs. Our team
                of experts ensures that your vehicles are always in top
                condition, keeping you on the road and saving you time and
                money.
              </li>
              <li>
                Fleet Tracking: With real-time GPS tracking, our system provides
                detailed information on the location, speed and status of your
                vehicles, helping you keep track of your fleet and make informed
                decisions.
              </li>
              <li>
                Fuel Management: Our fuel management system helps you track fuel
                consumption and expenses, ensuring that your fleet is running
                efficiently and cost-effectively.
              </li>
              <li>
                Driver Management: Our driver management system provides you
                with a comprehensive overview of your drivers' activities,
                including hours worked, distance travelled and fuel consumption.
              </li>
              <li>
                Compliance Management: We help ensure that your fleet is always
                compliant with local regulations, providing you with peace of
                mind and avoiding costly fines.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-4 mt-8 text-base">
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>100% Luxurious Fleet</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>All our Fleet Are Fully Valeted & Serviced</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>A Safe & Secure Journey</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>Comfortable and Enjoyable</p>
            </div>
            <div className="flex items-center gap-6">
              <div className="bg-[#FDEEEC] rounded-full p-2">
                <FiCheck className="text-[#E95440]" />
              </div>
              <p>Clean, Polite & knowledgeable</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCorperatefleet;
