import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { Table } from "antd";
import { getCookie } from "../../utils/constants";
import { vehicleIncomeAndExpenseAPI } from "../../redux/actions/myaccountAction";
import { useDispatch } from "react-redux";

const ExpensesTransaction = () => {
  const [transactions, setTransaction] = useState([]);
  const dispatch = useDispatch();
  const [metaDetails, setMetaDetails] = useState({});

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    let postData = {
      api_token: getCookie("token"),
    };

    vehicleIncomeAndExpenseAPI(dispatch, postData, "expense")
      .then((res) => {
        console.log(res);
        setTransaction(res.data);
        setMetaDetails(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyExpensesData = () => {
    const expensesData = [];
    transactions.forEach((item, index) => {
      expensesData.push({
        key: index,
        vehicle:
          item.vehicle.make_name +
          " " +
          item.vehicle.model_name +
          " " +
          item.vehicle.license_plate,
        expenses_type: item.category.name,
        amount: item.amount,
        date: item.date,
      });
    });

    // Sorting data in descending order based on key
    expensesData.sort((a, b) => {
      return b.key - a.key;
    });

    return expensesData;
  };

  const columns = [
    {
      title: "Vehicle",
      dataIndex: "vehicle",
    },
    {
      title: "Expenses Type",
      dataIndex: "expenses_type",
    },
    {
      title: "Amount GHS",
      dataIndex: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];

  return (
    <>
      <div className="w-full py-6 flex flex-col gap-2">
        <p className="font-bold text-xl">Expenses Transactions</p>
        <p className="font-text text-sm">Dashboard - Expenses Transactions</p>
      </div>
      <Card className="w-full p-6 flex flex-col gap-6">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-xl">Vehicle list</p>
        </div>
        <div className="w-full">
          {" "}
          <div className="overflow-x-auto max-w-full">
            <Table columns={columns} dataSource={getMyExpensesData()} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default ExpensesTransaction;
