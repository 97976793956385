import React, {useEffect, useRef, useState} from 'react'
import {Card, TextField} from "@mui/material";
import RButton from "../../../components/RButton";
import {BsArrowUpRight} from "react-icons/bs";
import {toast} from "react-toastify";
import { IoCloudUploadOutline } from "react-icons/io5";
import {getCookie} from "../../../utils/constants";
import {profileLicencesAPI} from "../../../redux/actions/myaccountAction";
import {useDispatch, useSelector} from "react-redux";
import {getInfoByToken} from "../../../redux/actions/authAction";
import {useNavigate} from "react-router-dom";
import LicenceDetails from "./LicenceDetails";
import {Modal} from "antd";
const { confirm } = Modal;

function ExclamationCircleFilled() {
    return null;
}

const LicenceComponent = () => {
    const passport = useRef(null);
    const license = useRef(null);
    const [licenseFile, setLicenseFile] = useState(null);
    const [passportFile, setPassportFile] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const checkFileSelected = () => {
        // just check if one minimum 1 file is selected
        if (licenseFile === null && passportFile === null) {
            toast.error("Please select at least one file");
            return false;
        }
        // file type must be image
        if (licenseFile !== null && licenseFile.type.indexOf("image") === -1) {
            toast.error("Please select image file for license");
            return false;
        }
        return true;
    }
    const getFileExtension = (file) => {
        if (file === null || file === undefined) return "";
        return '.' + file.name.split('.').pop();
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file === null || file === undefined) {
                resolve(null);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve({
                    file: reader.result,
                    extension: getFileExtension(file)
                });
                reader.onerror = error => reject(error);
            }
        });
    }
    const continueClicked = (r) => {
        let postData = {
            api_token: getCookie("token"),
        }
        if (r.licenseFileBase64 !== null) {
            postData.driver_licence = r.licenseFileBase64.file;
            postData.driver_licence_extension = r.licenseFileBase64.extension;
        }
        if (r.passportFileBase64 !== null) {
            postData.passport_id = r.passportFileBase64.file;
            postData.passport_id_extension = r.passportFileBase64.extension;
        }
        console.log(postData);
        profileLicencesAPI(dispatch, postData).then((res) => {
            console.log(res)
            toast.success("Successfully uploaded")
            getInfoByToken(dispatch, true, navigate);
            license.current.value = "";
            passport.current.value = "";
        }).catch((error) => {
            console.log(error)
        });
    }
    const convertBothFileToBase64 = async () => {
        const licenseFileBase64 = await getBase64(licenseFile);
        const passportFileBase64 = await getBase64(passportFile);
        return {licenseFileBase64, passportFileBase64};
    }
    const uploadClicked = () => {
        if (!checkFileSelected()) return;
        convertBothFileToBase64().then(r => {
            continueClicked(r);
        }).catch(e => {
            toast("Error in uploading file")
        })
    }

    useEffect(() => {
        console.log(user);
    }, [user]);
    useEffect(() => {
        getInfoByToken(dispatch, true, navigate);
    }, []);
    const showConfirm = () => {
        confirm({
            title: 'Do you Want to upload these items?',
            icon: <ExclamationCircleFilled />,
            content: 'Make sure your licences is valid and not expired, otherwise you will not be able to book a car',
            onOk() {
                console.log('OK');
                uploadClicked();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    return (
        <div className="grid w-full grid-cols-1 bg-white lg:grid-cols-3">
            {user?.id > 0 ? (
                <>
                    <div className="col-span-2">
                        <Card className="grid grid-cols-1 p-4 sm:w-full sm:flex gap-6">
                            <TextField
                                label="Upload License"
                                placeholder="Drivers License"
                                focused
                                fullWidth
                                InputProps={{
                                    // readOnly: true,
                                }}
                                type={"file"}
                                inputRef={license}
                                onChange={(e) => {
                                    console.log(e.target.files[0]);
                                    setLicenseFile(e.target.files[0]);
                                }}
                                className="col-span-1"
                            />
                            <div className="col-span-1 sm:flex-grow flex justify-center">
                                <RButton isradius={true} onClick={() => {
                                    license.current.click();
                                }}>
                                    <span className="flex w-full justify-center items-center gap-2 px-10">
                                      Select file
                                    </span>
                                </RButton>
                            </div>
                        </Card>
                        <Card className="grid grid-cols-1 p-4 sm:w-full sm:flex gap-6">
                            <TextField
                                label="Passport or ID Card Upload"
                                placeholder="Password or ID Card"
                                focused
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                type={"file"}
                                inputRef={passport}
                                onChange={(e) => {
                                    setPassportFile(e.target.files[0]);
                                }}
                                className="col-span-1"
                            />
                            <div className="col-span-1 sm:flex-grow flex justify-center">
                                <RButton isradius={true} onClick={() => {
                                    passport.current.click();
                                }}>
                                    <span className="flex w-full justify-center items-center gap-2 px-10">
                                      Select file
                                    </span>
                                </RButton>
                            </div>
                        </Card>
                        <div className="w-full mt-12">
                            <RButton isradius={true} isfullwidth={true} onClick={showConfirm}>
                                  <span className="flex w-full justify-center items-center gap-2 px-10">
                                    Upload <IoCloudUploadOutline className="font-bold"/>
                                  </span>
                            </RButton>
                        </div>
                    </div>
                    <div className="col-span-1 ml-2">
                        <LicenceDetails metas={user}/>
                    </div>
                </>
            ) : (
                <>
                    <h3>Login to upload documents</h3>
                </>
            )}
        </div>
    )
}

export default LicenceComponent
