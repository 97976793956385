import { useNavigate } from "react-router-dom";
import RButton from "../../../components/RButton";
import { Card } from "@mui/material";
import { Table } from "antd";
import { HiPlus } from "react-icons/hi";
import { getVehicleListAPI } from "../../../redux/actions/vehiclesActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCookie } from "../../../utils/constants";
import { MdEditCalendar } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import VehicleDetailsModal from "./VehicleDetailsModal";
import { FaRegEye } from "react-icons/fa";

const ManageVehicles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const columns = [
    {
      title: "Vehicle make",
      dataIndex: "vehicle_make",
      width: 120,
    },
    {
      title: "Vehicle model",
      dataIndex: "vehicle_model",
    },
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "License Plate Number",
      dataIndex: "license_plate_number",
    },
    {
      title: "Horse Power",
      dataIndex: "horse_power",
    },
    {
      title: "doors",
      dataIndex: "doors",
    },
    {
      title: "Action",
      render: (text) => (
        // show both botton in center
        <div className={"flex justify-center gap-2"}>
          <button
            onClick={() => {
              setSelectedVehicle(text.raw);
              setVisible(true);
            }}
            className="text-blue-500 border border-blue-500 rounded-md px-2 py-1 flex items-center gap-2"
          >
            <FaRegEye />
          </button>
          <button
            onClick={() => {
              navigate(`/edit_vehicle/${text.raw.id}`);
            }}
            className="text-blue-500 border border-blue-500 rounded-md px-2 py-1 flex items-center gap-2"
          >
            <CiEdit />
          </button>
        </div>
      ),
    },
    // {
    //   title: 'Activate',
    //   render: () => (<></>)
    // }
  ];

  const addVehicle = () => {
    navigate("/new_vehicle");
  };
  const getVehicles = () => {
    let postData = {
      api_token: getCookie("token"),
    };
    getVehicleListAPI(dispatch, postData)
      .then((res) => {
        console.log(res);
        setVehicles(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getVehicles();
  }, []);
  const vehicleShowData = () => {
    let data = [];
    vehicles.map((item, index) => {
      data.push({
        key: index,
        vehicle_make: item.make_name,
        vehicle_model: item.model_name,
        year: item.year,
        license_plate_number: item.license_plate,
        horse_power: item.horse_power,
        doors: item.doors,
        raw: item,
      });
    });
    return data;
  };
  const testingSomething = () => {
    console.log(vehicleShowData());
    console.log(vehicles);
  };
  return (
    <>
      <div className="w-full py-6 flex flex-col gap-2">
        <VehicleDetailsModal
          visible={visible}
          setVisible={setVisible}
          item={selectedVehicle}
        />
        {/*<button onClick={testingSomething}>testingSomething</button>*/}
        {/*<button onClick={getVehicles}>Get</button>*/}
        <p className="font-bold text-xl">Vehicle Management</p>
        <p className="font-text text-sm">Dashboard - vehicle management</p>
      </div>
      <Card className="w-full p-6 flex flex-col gap-6">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-xl">Vehicle list</p>
          <RButton isradius={false} onClick={() => addVehicle()}>
            <span className="flex items-center gap-2 text-sm">
              <HiPlus />
              Add Vehicle
            </span>
          </RButton>
        </div>
        <div className="w-full">
          <div className="overflow-x-auto max-w-full">
            <Table columns={columns} dataSource={vehicleShowData()} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default ManageVehicles;
