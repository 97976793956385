import { stringConstant, toast_options } from "../../utils/constants";
import {
    CLEAR_BOOKING_DETAILS,
    FCM_SETTINGS, GET_DISTANCE_COST,
    SET_BOOKING_MODAL_VISIBLE,
    SET_BRANCHES,
    SET_VEHICLE_DETAILS,
    SET_VEHICLE_ID,
    VEHICLE_BY_TYPE, VEHICLE_BY_TYPE_PAGINATION,
    VEHICLE_TYPE
} from "../type";
import {
    get_wholeday_booking,
    get_hourly_booking,
    get_fms_vehicle,
    addonList,
    fms_branches,
    fcm_vehicles_types, fcm_vehicle_by_type, create_booking, fcm_settings, get_distance, booking_payment, fms_booking
} from "../../utils/webAPIs";
import { toast } from "react-toastify";
import { SET_LOADING, SET_FLEET_LIST, SET_ADDONS } from "../../redux/type";
import axios from "axios";
import { BACKEND_URL } from "../../utils/config";
import fmsaxios from "../../utils/fmsaxios";

export const visibleBookingModal = (dispatch, visibility) => {
  dispatch({ type: SET_BOOKING_MODAL_VISIBLE, payload: visibility });
};

export const getVehicleAllList = (dispatch, postData, rtype) => {
  axios
    .post(`${BACKEND_URL}${ rtype === 1 ? get_hourly_booking : get_wholeday_booking }`, postData)
    .then((res) => {
        console.log(res);
      if (res.status === 200) {
        const { addons, fleet } = res.data;
        // dispatch({ type: SET_FLEET_LIST, payload: fleet });
        // dispatch({ type: SET_ADDONS, payload: addons });
      } else {
        toast.error(stringConstant.failed_register, toast_options);
      }
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch((err) => console.log(err));
};

export const setVehicleID = (dispatch, vehicleID) => {
  dispatch({ type: SET_VEHICLE_ID, payload: vehicleID });
}

export const setVehicleDetails = (dispatch, vehicleDetails) => {
    dispatch({ type: SET_VEHICLE_DETAILS, payload: vehicleDetails });
}


export const getVehicleAllListByFCM = (dispatch, postData, rtype) => {
    fmsaxios.get(get_fms_vehicle).then((res) => {
        console.log(res);
        if (res.status === 200) {
            const fleet = res.data.data;
            console.log(fleet);
            dispatch({ type: SET_FLEET_LIST, payload: fleet });
            // dispatch({ type: SET_ADDONS, payload: addons });
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
        dispatch({ type: SET_LOADING, payload: false });
    }).catch((err) => {
        console.log(err)
    })
}

export const getAddonData = (dispatch, id) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.post(addonList, {type: id}).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        console.log(res);
        if (res.status) {
            dispatch({ type: SET_ADDONS, payload: res.data.data });
        }
    }).catch((err) => {
        dispatch({type: SET_LOADING, payload: false});
    })
}

export const gettingBranches = (dispatch) => {
    fmsaxios.get(fms_branches).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        if (res.status) {
            dispatch({ type: SET_BRANCHES, payload: res.data.data });
        }
    }).catch((err) => {
        dispatch({type: SET_LOADING, payload: false});
    })
}


export const getVehiclesTypes = (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.get(fcm_vehicles_types).then((res) => {
        if (res.status === 200) {
            const types = res.data.data;
            dispatch({ type: VEHICLE_TYPE, payload: types });
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
        dispatch({ type: SET_LOADING, payload: false });
    }).catch((err) => {
        dispatch({ type: SET_LOADING, payload: false });
        console.log(err)
    })
}


export const getVehicleByType = (dispatch, postData, page) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.post(fcm_vehicle_by_type + '?page=' + page, postData).then((res) => {
        if (res.status === 200) {
            console.log(res);
            const types = res.data.data;
            dispatch({ type: VEHICLE_BY_TYPE, payload: types });
            dispatch({ type: VEHICLE_BY_TYPE_PAGINATION, payload: res.data.pagination });
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
        dispatch({ type: SET_LOADING, payload: false });
    }).catch((err) => {
        dispatch({ type: SET_LOADING, payload: false });
        console.log(err)
    })
}


export const createBooking = (dispatch, postData, navigate, setBookingStatus, setBookingResponse) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.post(create_booking, postData).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        console.log(res.data.data);
        if (res.data?.data?.id > 0) {
            toast.success('Booking Created Successfully', toast_options);
            // clear booking data and redirect to home page
            // dispatch({ type: CLEAR_BOOKING_DETAILS, payload: null });
            // navigate('/');
            setBookingResponse(res.data.data);
            setBookingStatus(true);
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
    }).catch((err) => {
        console.log(err.response.data);
        dispatch({type: SET_LOADING, payload: false});
    })
}
export const paymentAPI = (dispatch, postData, navigate, setBookingStatus, rePayment = false, setOnPaymentSaved = null) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.post(booking_payment, postData).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        console.log(res.data.data);
        if (res.data?.data?.id > 0) {
            toast.success('Booking payment saved', toast_options);
            // clear booking data and redirect to home page
            dispatch({ type: CLEAR_BOOKING_DETAILS, payload: null });
            if (rePayment) {
                setOnPaymentSaved(true);
            } else {
                navigate(`/order-invoice/${postData.booking_id}`);
                setBookingStatus(false);
            }
        } else {
            toast.error(stringConstant.failed_register, toast_options);
        }
    }).catch((err) => {
        console.log(err.response.data);
        dispatch({type: SET_LOADING, payload: false});
    })
}



export const gettingSettings = (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    fmsaxios.get(fcm_settings).then((res) => {
        dispatch({type: SET_LOADING, payload: false});
        if (res.status) {
            dispatch({ type: FCM_SETTINGS, payload: res.data.data });
        }
    }).catch((err) => {
        dispatch({type: SET_LOADING, payload: false});
    })
}

export const gettingDistanceCalculate = (data) => {
    return new Promise((resolve, reject) => {
        fmsaxios.post(get_distance, data).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}


export const getFMSBooking = (id, postData) => {
    return new Promise((resolve, reject) => {
        fmsaxios.post(fms_booking + '/' + id, postData).then((res) => {
            if (res.status === 200) {
                resolve(res.data.data);
            } else {
                toast.error(stringConstant.failed_register, toast_options);
                reject(res.data);
            }
        }).catch((err) => {
            reject(err);
        })
    })
}
