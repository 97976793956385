import React, {useEffect} from 'react'
import {Card} from "@mui/material";

const LicenceDetails = ({metas}) => {
    const getDetailsFromUser = (type) => {
        if (metas?.id > 0) {
            if (type === "driver_licence") {
                return metas?.driver_licence;
            }
        }
    }
    const checkingDate = (date) => {
        // date is in format of 2021-10-10 00:00:00
        if (date === null || date === undefined) {
            return "No expiry";
        }
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString();
    }
    return (
        <div>
            <Card className="p-4">
                <div className="flex justify-between">
                    <p className="text-md font-bold">
                        Driver License
                    </p>
                    <p className="text-xl font-bold text-right">
                        {getDetailsFromUser("driver_licence") === null ? "Not uploaded" : "Valid"}
                    </p>
                </div>
                <div className="flex justify-between">
                    <p className="text-md font-bold">
                        Expiry
                    </p>
                    <p className="text-xl font-bold text-right">
                        {checkingDate(metas?.driver_licence_expiry)}
                    </p>
                </div>
            </Card>
            <Card className="p-4 mt-4">
                <div className="flex justify-between">
                    <p className="text-md font-bold">
                        Passport or ID Card
                    </p>
                    <p className="text-xl font-bold text-right">
                        {getDetailsFromUser("passport_id") === null ? "Not uploaded" : "Valid"}
                    </p>
                </div>
                <div className="flex justify-between">
                    <p className="text-md font-bold">
                        Expiry
                    </p>
                    <p className="text-xl font-bold text-right">
                        {checkingDate(metas?.passport_id_expiry)}
                    </p>
                </div>
            </Card>
        </div>
    )
}

export default LicenceDetails